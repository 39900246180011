import { useSelector } from "react-redux";
import { RootState } from "../../../interfaces/ReduxState";
import { Link } from "react-router-dom";

import styles from "./AboutSystem.module.css";
import patern from "../../../assets/img/about-patern.svg";

const AboutSystem = () => {
  const user = useSelector((state: RootState) => state.user);

  return (
    <section className={styles.aboutSystem}>
      <div className={styles.container} data-aos="fade-up" data-aos-offset="0">
        <h2>Pasos sencillos para obtener su solución</h2>
        <img src={patern} alt="easy steps" />
      </div>
      <div
        className={styles.containerCards}
        data-aos="fade-up"
        data-aos-delay="0"
      >
        <div className={styles.aboutCard}>
          <img
            src="/place.svg"
            width="72px"
            height="72px"
            className="card-img-top"
            alt="Image1"
          />
          <div className={styles.cardBody}>
            <h3>Encuentra profesionales y negocios</h3>
            <p>
              Antes de reservar una cita, selecciona un servicio o un
              profesional.
            </p>
          </div>
        </div>
        <div
          className={styles.aboutCard}
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <img
            src="/choose-servise.svg"
            width="72px"
            height="72px"
            className="card-img-top"
            alt="Image2"
          />
          <div className={styles.cardBody}>
            <h3>Selecciona la ubicación</h3>
            <p>
              Ingresa tu localización y obtén asistencia en la programación de
              citas.
            </p>
          </div>
        </div>
        <div
          className={styles.aboutCard}
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <img
            src="/calendar.svg"
            width="72px"
            height="72px"
            className="card-img-top"
            alt="Image3"
          />
          <div className={styles.cardBody}>
            <h3>Programa tu cita</h3>
            <p>
              Selecciona el día y la hora que mejor se adapten a tus
              preferencias
            </p>
          </div>
        </div>
        <div
          className={styles.aboutCard}
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <img
            src="/light.svg"
            width="72px"
            height="72px"
            className="card-img-top"
            alt="Image4"
          />
          <div className={styles.cardBody}>
            <h3>Disfruta el servicio?</h3>
            <p>
              Te ayudaremos a programar citas, organizar tu agenda y brindar
              servicios de calidad.
            </p>
          </div>
        </div>
        <div className={styles.registerCompany} data-aos="fade-up">
          <h3>¿Quieres utilizar nuestro servicio de cita previa?</h3>
          <Link
            className="btn btn-outline-primary"
            to={user.id ? "/company-form" : "/signup"}
          >
            Registrar mi compañia
          </Link>
        </div>
      </div>
    </section>
  );
};

export default AboutSystem;
