import { closeLoading, openLoading } from "../../redux/actions/loading";
import { useDispatch, useSelector } from "react-redux";
import { Appointment } from "../../interfaces/Appointment";
import { RootState } from "../../interfaces/ReduxState";
import {
  deleteAppointment,
  getAppointments,
  setAppointment,
  updateAppointment,
} from "../../redux/actions/appointment";
import swal from "sweetalert";

interface UseAppointments {
  data: Appointment[];
  set: (appointments: Appointment) => Promise<any>;
  get: () => Promise<any>;
  update: (appointments: Appointment) => Promise<any>;
  remove: (appointmentId: string, companyId: string) => Promise<any>;
}

export function useAppointments(): UseAppointments {
  const dispatch = useDispatch();
  const appointments = useSelector((state: RootState) => state.appointments);
  const company = useSelector((state: RootState) => state.company);
  const user = useSelector((state: RootState) => state.user);

  async function setAppointmentItem(appointment: Appointment): Promise<any> {
    dispatch(openLoading());
    dispatch<any>(setAppointment(company.id!, appointment, user.email))
      .then(() => {
        dispatch(closeLoading());
        swal(
          "Error",
          "No se pudo crear el turno, inténtelo más tarde",
          "error"
        );
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(closeLoading());
        swal(
          "Error",
          "No se pudo crear el turno, inténtelo más tarde",
          "error"
        );
      });
  }

  async function getAllAppointments(): Promise<any> {
    dispatch(openLoading());
    dispatch<any>(getAppointments(company.id!))
      .then(() => {
        dispatch(closeLoading());
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(closeLoading());
        swal(
          "Error",
          "No se pudieron obtener los turnos, inténtelo más tarde",
          "error"
        );
      });
  }

  async function updateAppointmentItem(appointment: Appointment): Promise<any> {
    dispatch(openLoading());
    dispatch<any>(updateAppointment(appointment))
      .then(() => {
        dispatch(closeLoading());
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(closeLoading());
        swal(
          "Error",
          "No se pudo actualizar el turno, inténtelo más tarde",
          "error"
        );
      });
  }

  async function removeAppointmentById(
    appointmentId: string,
    companyId: string
  ): Promise<any> {
    swal({
      icon: "warning",
      text: "¿Seguro que desea eliminar este turno?",
      buttons: {
        Aceptar: true,
        Cancelar: true,
      },
    }).then((response) => {
      if (response === "Aceptar") {
        dispatch(openLoading());
        dispatch<any>(deleteAppointment(appointmentId, companyId))
          .then(() => {
            dispatch(closeLoading());
          })
          .catch((error: any) => {
            console.log(error);
            dispatch(closeLoading());
            swal(
              "Error",
              "No se pudo eliminar el turno, inténtelo más tarde",
              "error"
            );
          });
      }
    });
  }

  return {
    data: appointments,
    set: setAppointmentItem,
    get: getAllAppointments,
    update: updateAppointmentItem,
    remove: removeAppointmentById,
  };
}
