import { closeLoading, openLoading } from "../../redux/actions/loading";
import { useDispatch, useSelector } from "react-redux";
import { getAppointments } from "../../redux/actions/appointment";
import { RootState } from "../../interfaces/ReduxState";
import { Company } from "../../interfaces/Company";
import {
  disabledCompany,
  getCompany,
  setCompany,
  updateCompany,
} from "../../redux/actions/company";
import swal from "sweetalert";
import useSesion from "../useSesion";

interface UseCompany {
  data: Company;
  set: (service: Company, file: File | null) => Promise<any>;
  get: () => Promise<any>;
  getAppointments: () => Promise<void>;
  update: (service: Company, file: File | null) => Promise<any>;
  toggleState: () => Promise<any>;
}

export function useCompany(): UseCompany {
  const dispatch = useDispatch();
  const sesion = useSesion();
  const company = useSelector((state: RootState) => state.company);

  async function setServiceItem(
    company: Company,
    file: File | null
  ): Promise<any> {
    dispatch(openLoading());
    dispatch<any>(setCompany(company, sesion.user, file))
      .then(() => {
        dispatch(closeLoading());
        swal("Success", "Se creó la companía con éxito", "success");
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(closeLoading());
        swal(
          "Error",
          "Hubo un error al crear la companía, inténtelo más tarde",
          "error"
        );
      });
  }

  async function getMyCompany(): Promise<any> {
    if (sesion.user.CompanyId) {
      dispatch(openLoading());
      dispatch<any>(getCompany(sesion.user.CompanyId))
        .then(() => {
          dispatch(closeLoading());
        })
        .catch((error: any) => {
          console.log(error);
          dispatch(closeLoading());
          swal(
            "Error",
            "Hubo un error al obtener las companía, inténtelo más tarde",
            "error"
          );
        });
    }
  }

  async function getCompanyAppointments() {
    dispatch<any>(openLoading());
    dispatch<any>(getAppointments(company.id!))
      .then(() => {
        dispatch<any>(closeLoading());
      })
      .catch(() => {
        dispatch<any>(closeLoading());
      });
  }

  async function updateMyCompany(
    service: Company,
    file: File | null
  ): Promise<any> {
    dispatch(openLoading());
    dispatch<any>(updateCompany(service, file))
      .then(() => {
        dispatch(closeLoading());
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(closeLoading());
        swal(
          "Error",
          "Hubo un error al obtener las companía, inténtelo más tarde",
          "error"
        );
      });
  }

  async function toggleStateCompany(): Promise<any> {
    swal({
      text: "¿Seguro que desea dar de baja la compañia?",
      icon: "info",
      buttons: {
        Si: true,
        No: true,
      },
    }).then((response: string) => {
      if (response === "Si") {
        dispatch(openLoading());
        dispatch<any>(disabledCompany(company))
          .then(() => {
            dispatch(closeLoading());
          })
          .catch((error: any) => {
            console.log(error);
            dispatch(closeLoading());
            swal(
              "Error",
              "Hubo un error al cambiar el estado de la companía, inténtelo más tarde",
              "error"
            );
          });
      }
    });
  }

  function checkAccess<T extends Function>(callBack: T): T {
    const wrappedFunction: any = async (...args: any[]) => {
      if (await sesion.isSubscribed(true)) return await callBack(...args);
    };
    return wrappedFunction as T;
  }

  return {
    data: company,
    set: checkAccess(setServiceItem),
    get: getMyCompany,
    getAppointments: getCompanyAppointments,
    update: checkAccess(updateMyCompany),
    toggleState: checkAccess(toggleStateCompany),
  };
}
