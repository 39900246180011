import { MyThunkAction, RootState } from "../../../interfaces/ReduxState";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { Dispatch } from "react";
import { Services } from "../../../interfaces/Services";
import { db } from "../../../firebase/config";

export const SET_SERVICE = "SET_SERVICE";
export const GET_SERVICES = "GET_SERVICES";
export const UPDATE_SERVICE = "UPDATE_SERVICE";
export const DELETE_SERVICE = "DELETE_SERVICE";

const companiesColl = collection(db, "Companies");

export function setService(
  CompanyId: string,
  services: Services
): MyThunkAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    // Service collection
    const servicesColl = collection(doc(companiesColl, CompanyId), "Services");

    // Post new service
    const serviceDoc = doc(servicesColl);
    await setDoc(serviceDoc, services);

    // Add id
    const newService = { ...services, id: serviceDoc.id };

    try {
      dispatch({
        type: SET_SERVICE,
        payload: newService,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function getServices(
  CompanyId: string
): ThunkAction<Promise<void>, RootState, null, AnyAction> {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      // Service collection
      const servicesColl = collection(
        doc(companiesColl, CompanyId),
        "Services"
      );

      // Get data
      const snapshot = await getDocs(servicesColl);

      // Save data
      const services: any[] = [];
      snapshot.forEach((doc) => services.push(doc.data()));

      dispatch({
        type: GET_SERVICES,
        payload: services,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function updateService(
  CompanyId: string,
  service: Services
): MyThunkAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    // Service collection
    const servicesColl = collection(doc(companiesColl, CompanyId), "Services");

    // Update new service
    const staffDoc = doc(servicesColl, service.id);
    await updateDoc(staffDoc, { ...service });

    try {
      dispatch({
        type: UPDATE_SERVICE,
        payload: service,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function deleteService(
  CompanyId: string,
  servicesId: string
): MyThunkAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    // Service collection
    const servicesColl = collection(doc(companiesColl, CompanyId), "Services");

    // Delete new service
    const serviceDoc = doc(servicesColl, servicesId);
    await deleteDoc(serviceDoc);

    try {
      dispatch({
        type: DELETE_SERVICE,
        payload: servicesId,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}
