import { useSelector } from "react-redux";
import { RootState } from "../../interfaces/ReduxState";

import styles from "./CompanyHeader.module.css";

interface Props {
  img?: string;
  name?: string;
}

export default function CompanyHeader({ img, name }: Props) {
  const company = useSelector((state: RootState) => state.company);

  return (
    <header className={styles.header}>
      <div className={styles.logo}>
        {(img || company.logoImg) && (
          <img src={img || company.logoImg} alt="logo" />
        )}
      </div>
      <span className={styles.name}>{name || company.name}</span>
    </header>
  );
}
