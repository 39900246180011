import { DELETE_APPOINTMENT, SET_APPOINTMENT } from "../../actions/appointment";
import { Appointment } from "../../../interfaces/Appointment";
import { AnyAction } from "redux";
import {
  GET_MY_APPOINTMENTS,
  UPDATE_MY_APPOINTMENT,
} from "../../actions/myAppointment";
import {
  AppointmentState,
  initAppointmentState,
} from "../../../interfaces/ReduxState";

export const MyAppointmentReducer = (
  state: AppointmentState = initAppointmentState(),
  action: AnyAction
) => {
  switch (action.type) {
    case SET_APPOINTMENT:
      return [...state, action.payload as Appointment];

    case GET_MY_APPOINTMENTS:
      return action.payload;

    case UPDATE_MY_APPOINTMENT:
      return state.map((app: Appointment) =>
        app.id === action.payload.id ? action.payload : app
      );

    case DELETE_APPOINTMENT:
      return state.filter((app: Appointment) => app.id !== action.payload);

    default:
      return state;
  }
};
