import { SubsState } from "./Subscription";

export interface User {
  id?: string;
  name: string;
  surName: string;
  email: string;
  type: UserType;
  password?: string;
  CompanyId?: string;
  photo: {
    url: string;
    fileName: string;
  };
  subscription?: {
    id?: string;
    state: SubsState;
    date: Date;
    planId: string;
    tested: boolean;
  };
  InvitationId?: string;
}

export enum UserType {
  ANY = "",
  USER = "User",
  STAFF = "Staff",
}

export interface UserError {
  name: string;
  surName: string;
  photo: string;
  email: string;
  password?: string;
}

export const initUser = (): User => ({
  name: "",
  surName: "",
  photo: {
    url: "",
    fileName: "",
  },
  email: "",
  type: UserType.ANY,
});

export const initUserError = () => ({
  name: "",
  surName: "",
  photo: "",
  email: "",
});
