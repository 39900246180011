export interface Contact {
  name: string;
  email: string;
  phone: string;
  message: string;
}

export interface ContactError {
  name: string;
  email: string;
  phone: string;
  message: string;
}

export const initContact = (): Contact => ({
  name: "",
  email: "",
  phone: "",
  message: "",
});

export const initContactError = (): ContactError => ({
  name: "",
  email: "",
  phone: "",
  message: "",
});