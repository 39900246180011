import { formatDateHour } from "../../scripts/formatDateHour";
import { Appointment } from "../../interfaces/Appointment";

import styles from "./MyAppointments.module.css";
import editSvg from "../../assets/icons/edit.svg";
import removeSvg from "../../assets/icons/x-remove.svg";

interface Props {
  edit: boolean;
  appointment: Appointment;
  onEdit: (companyId: string, appointmentId: string) => void;
  onDelete: (appointmentId: string, companyId: string) => void;
}

export default function MyAppointments({
  edit,
  appointment,
  onEdit,
  onDelete,
}: Props) {
  return (
    <div className={styles.appointmentItem}>
      <div className={styles.contentImage}>
        {appointment.staffPhoto && (
          <img
            className={styles.companyImage}
            src={appointment.staffPhoto}
            alt=""
          />
        )}
      </div>
      <div className={styles.content}>
        <h5>{appointment.serviceName}</h5>
        <span>{appointment.staffName}</span>
        <span>{formatDateHour(appointment.date!)}</span>
      </div>
      <div className={styles.actions}>
        {edit && (
          <button
            className={styles.iconBtn}
            type="button"
            onClick={() => onEdit(appointment.companyId, appointment.id!)}
          >
            <img src={editSvg} alt="edit" />
          </button>
        )}
        <button
          className={styles.iconBtn}
          type="button"
          onClick={() => onDelete(appointment.id!, appointment.companyId)}
        >
          <img src={removeSvg} alt="remove" />
        </button>
      </div>
    </div>
  );
}
