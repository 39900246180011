import { Link, useLocation, useNavigate } from "react-router-dom";
import { closeLoading, openLoading } from "../../redux/actions/loading";
import { useDispatch, useSelector } from "react-redux";
import { collection, doc, getDoc } from "firebase/firestore";
import { singUpWithGoogle } from "../../redux/actions/profile";
import { setAppointment } from "../../redux/actions/appointment";
import { Appointment } from "../../interfaces/Appointment";
import { RootState } from "../../interfaces/ReduxState";
import { useState } from "react";
import { Company } from "../../interfaces/Company";
import { db } from "../../firebase/config";
import {
  Signin,
  SigninError,
  initSignin,
  initSigninError,
} from "../../interfaces/Signin";
import useSesion from "../../hooks/useSesion";
import swal from "sweetalert";

import Input from "../../components/Inputs/Input";
import Header from "../../components/Header/Header";
import CookiesModal from "../../components/CookiesModal/CookiesModal";

import styles from "./SignUp.module.css";
import svg1 from "../../assets/img/Vector-login-1.svg";
import svg2 from "../../assets/img/Vector-login-2.svg";

export default function SignUp() {
  const dispatch = useDispatch();
  const redirect = useNavigate();
  const userLogin = useSelector((state: RootState) => state.user);
  const location = useLocation();
  const sesion = useSesion();
  const [user, setUser] = useState<Signin>(initSignin());
  const [errors, setErrors] = useState<SigninError>(initSigninError());
  const [cookiesModal, setCookiesModal] = useState(true);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const name: string = e.target.name;
    const value: string = e.target.value;
    setUser({ ...user, [name]: value });
    setErrors({ ...errors, [name]: "" });
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const query = new URLSearchParams(location.search);
    const redirectTo = query.get("redirectTo");
    const company = query.get("company");

    if (handleValidations()) {
      sesion
        .signUp(user)
        .then(async () => {
          if (redirectTo === "appointment" && company) {
            let localtAppointment = localStorage.getItem("appointment");
            if (!localtAppointment) throw new Error("Appointment not found");
            let newAppointment: Appointment = JSON.parse(localtAppointment);
            newAppointment.date = new Date(
              newAppointment.date! as unknown as string
            );
            const companySnap = await getDoc(
              doc(collection(db, "Companies"), company)
            );
            let companyData = companySnap.data() as Company;
            companyData.id = company;

            dispatch<any>(openLoading());
            dispatch<any>(
              setAppointment(companyData.id, newAppointment, sesion.user.email)
            )
              .then(() => {
                swal("Agendado", "Se agendo tu nueva cita", "success");
                localStorage.removeItem("appointment");
                redirect("/");
                dispatch<any>(closeLoading());
              })
              .catch((error: any) => {
                swal("Error", "Surgio un error al cargar el turno", "error");
                console.log(error);
                dispatch<any>(closeLoading());
              });
          }
        })
        .catch((error) => {
          console.log(error.message);
          if (
            error.message.includes("Password should be at least 6 character")
          ) {
            setErrors({
              ...error,
              password: "La contraseña tiene que tener minimo 6 caracteres",
            });
          } else if (error.message.includes("auth/email-already-in-use")) {
            setErrors({
              ...error,
              email: "El correo ya esta en uso",
            });
          } else {
          }
        });
    }
  }

  function handleValidations() {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let errors: SigninError = initSigninError();
    let value = true;

    if (user.name === "") {
      errors.name = "Este campo es obligatorio";
      value = false;
    }

    if (user.surName === "") {
      errors.surName = "Este campo es obligatorio";
      value = false;
    }

    if (user.email === "") {
      errors.email = "Este campo es obligatorio";
      value = false;
    }

    if (!emailRegex.test(user.email)) {
      errors.email = "El formato del correo electrónico no es válido";
      value = false;
    }

    if (user.password === "") {
      errors.password = "Este campo es obligatorio";
      value = false;
    }

    if (user.password.length < 6) {
      errors.password = "La contraseña tiene que tener minimo 6 caracteres";
      value = false;
    }

    if (user.repeatPassword === "") {
      errors.repeatPassword = "Este campo es obligatorio";
      value = false;
    }

    if (user.repeatPassword !== user.password) {
      errors.repeatPassword = "Las contraseñas no coinciden";
      value = false;
    }

    setErrors(errors);
    return value;
  }

  const handleAcept = () => {
    setCookiesModal(!cookiesModal);
  };

  const handleDecline = () => {
    setCookiesModal(!cookiesModal);
  };

  function handleSignUpWithGoogle() {
    dispatch(openLoading());
    dispatch<any>(singUpWithGoogle())
      .then(() => {
        dispatch(closeLoading());
      })
      .catch((error: any) => {
        dispatch(closeLoading());
        console.log(error);
        swal("Hubo un error al iniciar sesión con google");
      });
  }

  return (
    <div className={styles.container}>
      {cookiesModal && !userLogin.id && (
        <CookiesModal handleAcept={handleAcept} handleDecline={handleDecline} />
      )}
      <img className={styles.svgVector1} src={svg1} alt="" />
      <img className={styles.svgVector2} src={svg2} alt="" />
      <Header text="Registrarse">
        <div className="d-flex flex-column justify-content-center align-items-center w-100">
          <form
            className="d-flex flex-column align-items-center justify-content-center max-w-500 w-100"
            onSubmit={handleSubmit}
          >
            <div className="d-flex flex-column gap-2 align-items-center justify-content-center  w-100 p-3">
              <div className="d-flex gap-4 w-100">
                <Input
                  type="text"
                  name="name"
                  label="Nombre *"
                  value={user.name}
                  error={errors.name}
                  handleChange={handleChange}
                />
                <Input
                  type="text"
                  name="surName"
                  label="Apellido *"
                  value={user.surName}
                  error={errors.surName}
                  handleChange={handleChange}
                />
              </div>
              <Input
                type="email"
                name="email"
                label="Correo *"
                value={user.email}
                error={errors.email}
                handleChange={handleChange}
              />
              <Input
                type="phone"
                name="phone"
                label="Telefono"
                value={user.phone}
                error={errors.phone}
                handleChange={handleChange}
              />
              <Input
                type="password"
                name="password"
                label="Contraseña *"
                value={user.password}
                error={errors.password}
                handleChange={handleChange}
              />
              <Input
                type="password"
                name="repeatPassword"
                label="Repite la contraseña *"
                value={user.repeatPassword}
                error={errors.repeatPassword}
                handleChange={handleChange}
              />
            </div>
            <div className="d-flex flex-row w-100 align-items-center gap-5 p-3">
              <button
                className="btn btn-primary bg-celeste w-100 border-celeste fw-bold"
                type="submit"
              >
                Registrarme
              </button>
            </div>
            <div className={styles.orLineContainer}>
              <div className={styles.orLine}></div>
              <span className="text-body-sm text-gray mb-1">o</span>
              <div className={styles.orLine}></div>
            </div>
            {/*             <button
              className={`${styles.btnShadow} btn btn-primary bg-white border-0 text-gray text-body-sm d-flex flex-row justify-content-start align-items-center px-3 py-2`}
              type="button"
              onClick={handleSignUpWithGoogle}
            >
              <img
                src="/google.svg"
                width={20}
                height={20}
                alt="Google"
                className="me-3"
              />
              <span className="text-body-sm me-3 text-gray">
                Iniciar con Google
              </span>
            </button> */}
            <span className={styles.loginLink}>
              ¿Ya te has registrado? <Link to="/login">Iniciar sesión</Link>
            </span>
          </form>
        </div>
      </Header>
    </div>
  );
}
