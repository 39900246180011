import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { RootState } from "../../../interfaces/ReduxState";
import { UserType } from "../../../interfaces/User";
import { useState } from "react";
import { logOut } from "../../../redux/actions/profile";
import swal from "sweetalert";

import styles from "./LoguedMenu.module.css";
import arrow from "../../../assets/icons/arrow.svg";

export default function LoguedMenu() {
  const redirect = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const [menu, setMenu] = useState(false);

  function handleMenu() {
    setMenu(!menu);
  }

  function handleLogout() {
    swal({
      text: "¿Quiere cerrar sesión?",
      icon: "info",
      buttons: {
        Si: true,
        No: true,
      },
    }).then((response) => {
      if (response === "Si") {
        dispatch<any>(logOut()).then(() => redirect("/login"));
        setMenu(false);
        redirect("/login");
      }
    });
  }

  function handleRedirect(path: string) {
    setMenu(!menu);
    redirect(path);
  }

  return (
    <div className={styles.profile}>
      <Link to={"/"}>Inicio</Link>
      <Link to={"pricing"}>Servicios</Link>
      <button
        className={`${styles.profileButton} btn btn-primary`}
        type="button"
        onClick={handleMenu}
      >
        <span>
          {user.name} {user.surName}
        </span>
        <img src={arrow} alt="arrow" />
      </button>
      {menu && (
        <ul className={styles.menu}>
          <li>
            <span onClick={() => handleRedirect("/my-acount")}>Mi cuenta</span>
          </li>
          <li>
            <hr></hr>
          </li>
          <li>
            {user.CompanyId ? (
              <span onClick={() => handleRedirect("/my-company")}>
                Mi empresa
              </span>
            ) : (
              <span onClick={() => handleRedirect("/company-form")}>
                Registrar mi empresa
              </span>
            )}
          </li>
          {user.CompanyId && user.type !== UserType.STAFF && (
            <li>
              <span onClick={() => handleRedirect("/staff")}>Staff</span>
            </li>
          )}
          {user.CompanyId && user.type !== UserType.STAFF && (
            <li>
              <span onClick={() => handleRedirect("/services")}>Servicios</span>
            </li>
          )}
          <li>
            <hr></hr>
          </li>
          <li onClick={handleLogout}>
            <span>Log out</span>
          </li>
        </ul>
      )}
    </div>
  );
}
