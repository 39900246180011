import { closeLoading, openLoading } from "../../redux/actions/loading";
import { useDispatch, useSelector } from "react-redux";
import { collection, doc, getDoc } from "firebase/firestore";
import { useLocation, useNavigate } from "react-router-dom";
import { SubsState } from "../../interfaces/Subscription";
import { RootState } from "../../interfaces/ReduxState";
import { LoginData } from "../../interfaces/Login";
import { useEffect } from "react";
import { auth, db } from "../../firebase/config";
import { Signin } from "../../interfaces/Signin";
import { User, UserType } from "../../interfaces/User";
import {
  getUser,
  logIn,
  logOut,
  singUp,
  suscribeTest,
} from "../../redux/actions/profile";
import swal from "sweetalert";

export interface Sesion {
  user: User;
  signUp: (user: Signin) => Promise<void>;
  logIn: (user: LoginData) => Promise<void>;
  logOut: () => Promise<void>;
  autoLogin: () => Promise<void>;
  isSubscribed: (message?: boolean) => Promise<boolean>;
  initTest: () => Promise<void>;
}

export default function useSesion(): Sesion {
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const redirect = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (user.InvitationId && !location.pathname.includes("invitation")) {
      swal({
        text: "Tienes una invitacion pendiente",
        buttons: {
          Ver: true,
          Despues: true,
        },
      }).then((response) => {
        if (response === "Ver") {
          redirect(`/invitation?token=${user.InvitationId}`);
        }
      });
    }
  }, [user]);

  async function signUpNewUser(user: Signin): Promise<void> {
    dispatch(openLoading());
    return dispatch<any>(singUp(user))
      .then(() => {
        redirect("/");
        dispatch(closeLoading());
      })
      .catch((error: any) => {
        dispatch(closeLoading());
        throw new Error(error);
      });
  }

  async function autoLogin(): Promise<void> {
    auth.authStateReady().then((data) => {
      dispatch(openLoading());
      if (auth.currentUser) {
        dispatch<any>(getUser())
          .then(() => dispatch(closeLoading()))
          .catch((error: any) => {
            console.log(error);
            dispatch(closeLoading());
            redirect("/login");
            swal(
              "Error",
              "Hubo un error desconocido al iniciar sesion",
              "error"
            );
          });
      } else {
        dispatch(closeLoading());
      }
    });
  }

  async function logInUser(user: LoginData): Promise<void> {
    dispatch(openLoading());
    return dispatch<any>(logIn(user))
      .then(() => {
        redirect("/");
        dispatch(closeLoading());
      })
      .catch((error: any) => {
        dispatch(closeLoading());
        throw new Error(error);
      });
  }

  async function logOutUser(): Promise<void> {
    dispatch(openLoading());
    dispatch<any>(logOut())
      .then(() => {
        redirect("/");
        dispatch(closeLoading());
      })
      .catch((error: Error) => {
        console.log(error);
        dispatch(closeLoading());
        swal("Error", "No se pudo cerrar sesión, inténtelo más tarde");
      });
  }

  async function isSubscribed(message?: boolean): Promise<boolean> {
    // Get user data
    const userColl = collection(db, "Users");
    const userDoc = doc(userColl, user.id!);

    // Get Data
    dispatch(openLoading());
    const userSnapshot = await getDoc(userDoc);
    const userData = userSnapshot.data() as User;
    dispatch(closeLoading());

    // Check subscription state
    if (
      (userData.CompanyId && userData.type === UserType.STAFF) ||
      (userData.subscription &&
        userData.subscription.state !== SubsState.CANCELED)
    ) {
      return true;
    } else {
      if (message) {
        swal({
          title: "No estas suscripto",
          text: "Debes suscribirte para poder acceder a esta función",
          icon: "warning",
          buttons: {
            Suscribirme: true,
            Cancelar: true,
          },
        }).then((response) => {
          if (response === "Suscribirme") {
            redirect("/pricing");
          } else {
            redirect("/");
          }
        });
      }
      return false;
    }
  }

  async function initTest() {
    if (
      !(user.CompanyId && user.type === UserType.STAFF) ||
      (user.subscription && user.subscription.state !== SubsState.CANCELED)
    ) {
      swal({
        text: "¿Quieres empezar el período de prueba de 30 días?",
        buttons: {
          Empezar: true,
          No: true,
        },
      }).then((response: string) => {
        if (response === "Empezar") {
          dispatch(openLoading());
          dispatch<any>(suscribeTest(user))
            .then(() => {
              swal({
                text: "Ya estas suscripto a la prueba de 30 días",
                icon: "success",
              });
              dispatch(closeLoading());
            })
            .catch((error: Error) => {
              redirect("/");
              console.log(error);
              dispatch(closeLoading());
            });
        } else {
          redirect("/");
        }
      });
    }
  }

  return {
    user: user,
    signUp: signUpNewUser,
    logIn: logInUser,
    logOut: logOutUser,
    autoLogin: autoLogin,
    isSubscribed: isSubscribed,
    initTest: initTest,
  };
}
