import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useCompany } from "../../../hooks/useCompany";
import { SubsState } from "../../../interfaces/Subscription";
import { RootState } from "../../../interfaces/ReduxState";
import {
  Company,
  CompanyHours,
  initCompany,
  initCompanyHours,
} from "../../../interfaces/Company";
import useSesion from "../../../hooks/useSesion";

import Input from "../../../components/Inputs/Input";
import DateSelector from "./DateSelector/DateSelector";
import MapContainer from "../../../components/MapContainer/MapContainer";
import SelectInput from "../../../components/Inputs/SelectInput";
import ProtectedRoute from "../../../components/ProtectedRoute/ProtectedRoute";

import styles from "./CompanyForm.module.css";
import clitpSvg from "../../../assets/icons/clip.svg";

const days = [
  { label: "Lunes", value: "monday" },
  { label: "Martes", value: "tuesday" },
  { label: "Miercoles", value: "wednesday" },
  { label: "Jueves", value: "thursday" },
  { label: "Viernes", value: "friday" },
  { label: "Sabado", value: "saturday" },
  { label: "Domingo", value: "sunday" },
];

export default function CompanyForm() {
  const redirect = useNavigate();
  const companies = useCompany();
  const user = useSelector((state: RootState) => state.user);
  const { initTest } = useSesion();
  const [company, setCompany] = useState<Company>(initCompany());
  const [image, setImage] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    if (
      user.id &&
      (!user.subscription || user.subscription.state === SubsState.CANCELED)
    ) {
      initTest();
    }
  }, [user]);

  // If exist the company set data to edit
  useEffect(() => {
    if (user.CompanyId) setCompany(companies.data);
  }, [companies.data]);

  // Return to landing
  function handleClose() {
    redirect("/");
  }

  // Submit company
  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();

    user.CompanyId
      ? companies.update(company, file).then(() => redirect("/my-company"))
      : companies.set(company, file).then(() => redirect("/my-company"));
  }

  // Change company data
  function handleChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) {
    setCompany({ ...company, [event.target.name]: event.target.value });
  }

  // Change location
  function handleLocation(location: string) {
    setCompany({ ...company, location });
  }

  // Select image logo
  function handleFile(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];
    if (file) {
      setFile(file);
      setImage(URL.createObjectURL(file));
    }
  }

  // Change time to any hour
  function handleChangeHours(
    day: keyof Company["hours"],
    index: number,
    newHour: CompanyHours
  ) {
    setCompany((prevCompany) => {
      return {
        ...prevCompany,
        hours: {
          ...prevCompany.hours,
          [day]: prevCompany.hours[day]?.map((hour, i) =>
            i === index ? newHour : hour
          ),
        },
      };
    });
  }

  // Enable day to change
  function handleEnableDay(day: keyof Company["hours"]) {
    setCompany((prevCompany) => {
      return {
        ...prevCompany,
        hours: {
          ...prevCompany.hours,
          [day]: prevCompany.hours[day]
            ? null
            : prevCompany.hours.monday || [initCompanyHours()],
        },
      };
    });
  }

  // Add a new time selector on day
  function handleAddHour(day: keyof Company["hours"]) {
    setCompany({
      ...company,
      hours: {
        ...company.hours,
        [day]: [...company.hours[day]!, initCompanyHours()],
      },
    });
  }

  // Remove any hour to any day
  function handleRemoveHour(day: keyof Company["hours"], index: number) {
    setCompany({
      ...company,
      hours: {
        ...company.hours,
        [day]: company.hours[day]?.filter((h, i) => i !== index),
      },
    });
  }

  return (
    <ProtectedRoute>
      <div className={styles.background}>
        <header className={styles.header}>
          <span className="back-to-table" onClick={handleClose}>
            {"< Volver al inicio"}
          </span>
          <h3 className="title-header">Agregar compañía</h3>
        </header>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div>
            <Input
              name="name"
              label="Nombre de la compañía"
              value={company.name}
              handleChange={handleChange}
            />
            <div className={styles.imgSelector}>
              <div className={styles.selectImg}>
                <input id="photoImg" type="file" onChange={handleFile} />
                <img src={clitpSvg} alt={clitpSvg} />
                <label htmlFor="photoImg">Adjuntar Logo</label>
              </div>
              <div className={styles.img}>
                <img src={image || companies.data.logoImg} alt="" />
              </div>
            </div>
          </div>
          <SelectInput
            name="category"
            label="Categoría"
            value={company.category}
            list={[
              "Centro de estética",
              "Spa",
              "Maquillaje",
              "Uñas",
              "Cejas y pestañas",
              "Masajes",
              "Depilación",
              "Tatuajes",
              "Peluquería",
              "Barberia",
              "Peluquerías mascotas",
              "Asesorías",
              "Consultorio",
              "Clases particulares",
              "Guarderías de mascotas",
              "Logopedas",
            ].sort((a, b) => a.localeCompare(b))}
            handleChange={handleChange}
          />
          <MapContainer
            location={company.location}
            error=""
            setLocation={handleLocation}
          />
          <div className={styles.hours}>
            <span>Horarios de atención</span>
            <div className={styles.hoursContainer}>
              {days.map((day) => (
                <DateSelector
                  day={day.label}
                  data={company.hours[day.value as keyof Company["hours"]]}
                  onEnable={() =>
                    handleEnableDay(day.value as keyof Company["hours"])
                  }
                  setHours={(index: number, newHour: CompanyHours) =>
                    handleChangeHours(
                      day.value as keyof Company["hours"],
                      index,
                      newHour
                    )
                  }
                  onAdd={() =>
                    handleAddHour(day.value as keyof Company["hours"])
                  }
                  onRemove={(index: number) =>
                    handleRemoveHour(day.value as keyof Company["hours"], index)
                  }
                />
              ))}
            </div>
          </div>
          <div className={styles.bntContainer}>
            <button
              className="btn btn-outline-primary"
              type="button"
              onClick={() =>
                companies.data.id ? redirect("/my-company") : redirect("/")
              }
            >
              Cancelar
            </button>
            <button className="btn btn-primary" type="submit">
              {companies.data.id ? "Actualizar" : "Crear"}
            </button>
          </div>
        </form>
      </div>
    </ProtectedRoute>
  );
}
