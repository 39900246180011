import { collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { MyThunkAction } from "../../../interfaces/ReduxState";
import { AnyAction } from "redux";
import { auth, db } from "../../../firebase/config";
import { Dispatch } from "react";
import { Staff } from "../../../interfaces/Staff";
import axios from "axios";

export const SET_STAFF = "SET_STAFF";
export const GET_STAFFS = "GET_STAFFS";
export const UPDATE_STAFF = "UPDATE_STAFF";
export const DELETE_STAFF = "DELETE_STAFF";

const usersColl = collection(db, "Users");

export function sendInvitation(staff: Staff, companyId: string): MyThunkAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      await axios.post("/staff", {
        staff,
        companyId,
      });
      dispatch({
        type: SET_STAFF,
        payload: staff,
      });
    } catch (e: any) {
      throw new Error(e.response?.data || e);
    }
  };
}

export function getStaffs(companyId: string): MyThunkAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    const response = await axios.get(`/staff/${companyId}`);
    const staffData: Staff[] = response.data;

    try {
      dispatch({
        type: GET_STAFFS,
        payload: staffData,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function updateStaff(staff: Staff): MyThunkAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    // Check Staff
    if (!auth.currentUser) throw new Error("Staff not logued");

    // Update Staff
    const response = await axios.patch("/staff", staff);
    const newStaff = response.data;
    if (!newStaff) throw new Error("Staff not updated");

    // Staff update
    await updateDoc(doc(usersColl, newStaff.id), { ...newStaff });

    try {
      dispatch({
        type: UPDATE_STAFF,
        payload: newStaff,
      });
    } catch (e: any) {
      console.log(e);
      throw new Error(e.response.data || e);
    }
  };
}

export function deleteStaff(companyId: string, email: string): MyThunkAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    // Delete staff to company
    await axios.patch("/staff/delete", {
      userEmail: email,
      companyId,
    });

    try {
      dispatch({
        type: DELETE_STAFF,
        payload: email,
      });
    } catch (e: any) {
      throw new Error(e.response.data || e);
    }
  };
}
