import { Company, initCompany } from "./Company";
import { User, initUser } from "./User";
import { ThunkAction } from "redux-thunk";
import { Appointment } from "./Appointment";
import { AnyAction } from "redux";
import { Services } from "./Services";
import { Staff } from "./Staff";

export type UserState = User;
export type CompanyState = Company;
export type ServicesState = Services[];
export type StaffState = Staff[];
export type AppointmentState = Appointment[];

export interface RootState {
  loading: boolean;
  user: UserState;
  company: CompanyState;
  services: ServicesState;
  staff: StaffState;
  appointments: AppointmentState;
  myAppointments: AppointmentState;
}

export type MyThunkAction = ThunkAction<
  Promise<void>,
  RootState,
  null,
  AnyAction
>;

export const initUserState = (): UserState => initUser();
export const initCompanyState = (): CompanyState => initCompany();
export const initServicesState = (): ServicesState => [];
export const initStaffState = (): StaffState => [];
export const initAppointmentState = (): AppointmentState => [];
