import { closeLoading, openLoading } from "../../redux/actions/loading";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { updateProfile } from "../../redux/actions/profile";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../interfaces/ReduxState";
import {
  User,
  initUser,
  UserError,
  initUserError,
} from "../../interfaces/User";

import Input from "../../components/Inputs/Input";
import Header from "../Header/Header";

import styles from "./Profile.module.css";

interface Props {
  handleClose: () => void;
}

export default function Profile({ handleClose }: Props) {
  const redirect = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const [userEdit, setUserEdit] = useState<User>(initUser());
  const [errors, setErrors] = useState<UserError>(initUserError());

  // Set user data
  useEffect(() => {
    setUserEdit(user);
  }, [user]);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    console.log(e.target.name);
    if (e.target.name !== "email") {
      setUserEdit({ ...user, [e.target.name]: e.target.value });
      setErrors({ ...errors, [e.target.name]: "" });
    }
  }

  function handleValidations() {
    const error: UserError = initUserError();
    let value: boolean = true;

    if (user.name === "") {
      error.name = "Name is required";
      value = false;
    }

    if (user.surName === "") {
      error.surName = "Last name is required";
      value = false;
    }

    setErrors(error);
    return value;
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (handleValidations()) {
      dispatch<any>(openLoading());
      console.log("Actualizando perfil");
      dispatch<any>(updateProfile(userEdit))
        .then(() => {
          dispatch<any>(closeLoading());
          handleClose();
        })
        .catch((error: any) => {
          console.log(error);
          dispatch<any>(closeLoading());
        });
    }
  }

  const handleChangePassword = () => {
    // redirect("/change-password");
  };

  const handleChangeEmail = () => {
    redirect("/change-email");
  };

  return (
    <div className={styles.background}>
      <Header text="Editar perfil">
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.inputs}>
            <Input
              type="text"
              name="name"
              label="Nombre"
              value={userEdit.name}
              error={errors.name}
              handleChange={handleChange}
            />
            <Input
              type="text"
              name="surName"
              label="Apellido"
              value={userEdit.surName}
              error={errors.surName}
              handleChange={handleChange}
            />
            <Input
              type="email"
              name="email"
              label="Email"
              value={user.email}
              error={errors.email}
              disabled={true}
              handleChange={handleChange}
            />
            <div className={styles.resetButtons}>
              <button
                className="btn btn-outline-primary"
                type="button"
                onClick={handleChangePassword}
              >
                Cambiar contraseña
              </button>
              <button
                className="btn btn-outline-primary"
                type="button"
                onClick={handleChangeEmail}
              >
                Cambiar correo
              </button>
            </div>
          </div>
          <div className={styles.formButtons}>
            <button className="btn btn-primary" type="submit">
              Guardar
            </button>
            <button
              className="btn btn-outline-primary"
              type="button"
              onClick={handleClose}
            >
              Cancelar
            </button>
          </div>
        </form>
      </Header>
    </div>
  );
}
