import { closeLoading, openLoading } from "../../redux/actions/loading";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../interfaces/ReduxState";
import { Staff } from "../../interfaces/Staff";
import {
  deleteStaff,
  getStaffs,
  sendInvitation,
  updateStaff,
} from "../../redux/actions/staff";
import useSesion from "../useSesion";
import swal from "sweetalert";

interface UseStaff {
  data: Staff[];
  invite: (staff: Staff, photo?: File | null) => Promise<any>;
  get: () => Promise<any>;
  update: (staff: Staff) => Promise<any>;
  remove: (staffId: string) => Promise<any>;
}

export function useStaff(): UseStaff {
  const dispatch = useDispatch();
  const Staff = useSesion();
  const staff = useSelector((state: RootState) => state.staff);
  const company = useSelector((state: RootState) => state.company);

  async function inviteUser(staff: Staff): Promise<any> {
    dispatch(openLoading());
    await dispatch<any>(sendInvitation(staff, company.id!))
      .then(() => {
        dispatch(closeLoading());
      })
      .catch((error: any) => {
        dispatch(closeLoading());
        throw new Error(error);
      });
  }

  async function getAllStaff(): Promise<any> {
    dispatch(openLoading());
    await dispatch<any>(getStaffs(company.id!))
      .then(() => {
        dispatch(closeLoading());
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(closeLoading());
        swal(
          "Error",
          "No se pudo obtener al staff, inténtelo más tarde",
          "error"
        );
      });
  }

  async function updateStaffUser(staff: Staff): Promise<any> {
    dispatch(openLoading());
    await dispatch<any>(updateStaff(staff))
      .then(() => {
        dispatch(closeLoading());
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(closeLoading());
        swal(
          "Error",
          "No se pudo actualizar el staff, inténtelo más tarde",
          "error"
        );
      });
  }

  async function removeStaff(email: string): Promise<any> {
    swal({
      icon: "warning",
      text: "Seguro que desea eliminarlo de tu empresa",
      buttons: {
        Eliminar: true,
        Cancelar: true,
      },
    }).then((response: string) => {
      if (response === "Eliminar") {
        dispatch(openLoading());
        dispatch<any>(deleteStaff(company.id!, email))
          .then(() => {
            dispatch(closeLoading());
            swal("Eliminado", "Se eliminó el staff con éxito", "success");
          })
          .catch((error: any) => {
            console.log(error);
            dispatch(closeLoading());
            swal(
              "Error",
              "No se pudo eliminar el staff, inténtelo más tarde",
              "error"
            );
          });
      }
    });
  }

  function checkAccess<T extends Function>(callBack: T): T {
    const wrappedFunction: any = async (...args: any[]) => {
      if (await Staff.isSubscribed(true)) return await callBack(...args);
    };
    return wrappedFunction as T;
  }

  return {
    data: staff,
    invite: checkAccess(inviteUser),
    get: getAllStaff,
    update: checkAccess(updateStaffUser),
    remove: checkAccess(removeStaff),
  };
}
