import { useEffect, useState } from "react";
import { useAppointments } from "../../../hooks/useAppointment";
import { CompanyState } from "../../../interfaces/Company";
import { Appointment } from "../../../interfaces/Appointment";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCompany } from "../../../hooks/useCompany";
import { SubsState } from "../../../interfaces/Subscription";
import { RootState } from "../../../interfaces/ReduxState";
import { UserType } from "../../../interfaces/User";
import useSesion from "../../../hooks/useSesion";

import Profile from "../../../components/Profile/Profile";
import QRCodeView from "./QRCodeView/QRCodeView";
import MyAppointments from "../../../components/MyAppointements/MyAppointments";
import ProtectedRoute from "../../../components/ProtectedRoute/ProtectedRoute";

import styles from "./MyCompany.module.css";
import editSvg from "../../../assets/icons/edit.svg";
import copySvg from "../../../assets/icons/copy.svg";
import checkSvg from "../../../assets/icons/check.svg";
import qrCodeSvg from "../../../assets/icons/qr-code.svg";

export default function MyCompany() {
  const redirect = useNavigate();
  const { initTest } = useSesion();
  const appointments = useAppointments();
  const company = useCompany();
  const user = useSelector((state: RootState) => state.user);
  const [code, setCode] = useState(false);
  const [profile, setProfile] = useState<boolean>(false);
  const [copied, setCopied] = useState<boolean>(false);
  const [rows, setRows] = useState<Appointment[]>([]);
  const [itemSelected, setItemSelected] = useState<number>(1);
  const link = `${process.env.REACT_APP_URL_WEB}/appointment/${user.CompanyId}`;

  useEffect(() => {
    if (appointments.data.length > 0) {
      appointments.get();
    }
  }, []);

  useEffect(() => {
    setRows(
      appointments.data.filter((appoint) => {
        const now = new Date();
        if (
          itemSelected === 1 &&
          appoint.date &&
          appoint.date.getTime() > now.getTime()
        )
          return true;
        else if (
          itemSelected === 2 &&
          appoint.date &&
          appoint.date.getTime() < now.getTime()
        )
          return true;
        return false;
      })
    );
  }, [appointments.data, itemSelected]);

  useEffect(() => {
    if (
      user.id &&
      user.type !== UserType.STAFF &&
      (!user.subscription || user.subscription.state === SubsState.CANCELED)
    ) {
      initTest();
    }
  }, [user]);

  useEffect(() => {
    if (user.CompanyId) company.get();
  }, [user]);

  useEffect(() => {
    if (company.data.id) company.getAppointments();
  }, [company.data]);

  // Toggle to view QR
  function handleQRCodeView() {
    setCode(!code);
  }

  // Redirect edit form
  function handleProfileForm() {
    setProfile(!profile);
  }

  // Redirect edit form
  function handleCompanyForm() {
    redirect("/company-form");
  }

  // Copy link
  async function handleCopy() {
    if (!copied) {
      handleViewCheck();
      await navigator.clipboard.writeText(link);
    }
  }

  // Show copy check
  function handleViewCheck() {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }

  // Edit an appointment
  const handleEditAppointment = () => {
    // Edit appointment form to add the edit option
  };

  return (
    <ProtectedRoute>
      <div className={styles.background}>
        {profile && <Profile handleClose={handleProfileForm} />}
        {code && (
          <QRCodeView
            companyName={company.data.name}
            link={link}
            handleClose={handleQRCodeView}
          />
        )}
        <div className={styles.container}>
          <div className={styles.data}>
            <div className={styles.profile}>
              <h5>Mi perfil</h5>
              <div className={styles.grid}>
                <div className={styles.profileContent}>
                  <img className={styles.profileImage} src={""} alt="" />
                </div>
                <h2>{user.name}</h2>
                <button
                  className={styles.iconBtn}
                  type="button"
                  onClick={handleProfileForm}
                >
                  <img src={editSvg} alt="edit" />
                </button>
                <span>{user.email}</span>
              </div>
            </div>
            <div className={styles.company}>
              <h5>Mi Compañia</h5>
              <div className={styles.grid}>
                <div className={styles.companyContent}>
                  <img
                    className={styles.companyImage}
                    src={company.data.logoImg}
                    alt=""
                  />
                </div>
                <h2>{company.data.name}</h2>
                {user.type !== UserType.STAFF && (
                  <button
                    className={styles.iconBtn}
                    type="button"
                    onClick={handleCompanyForm}
                  >
                    <img src={editSvg} alt="edit" />
                  </button>
                )}
                {company.data.state === CompanyState.ACTIVE ? (
                  <div className={styles.companystate}>
                    <span className={styles.active}>Activo - </span>
                    <button
                      className="btn btn-outline-primary"
                      type="button"
                      onClick={company.toggleState}
                    >
                      Dar de baja
                    </button>
                  </div>
                ) : (
                  <div className={styles.companystate}>
                    <span className={styles.inactive}>Inactivo - </span>
                    <button
                      className="btn btn-outline-primary"
                      type="button"
                      onClick={company.toggleState}
                    >
                      Dar de alta
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.link}>
              <h5>Compartir link</h5>
              <div className={styles.linkContent}>
                <span>{link}</span>
                <button
                  className={styles.iconBtn}
                  type="button"
                  onClick={handleCopy}
                >
                  <img
                    className={`${copied ? styles.hidden : styles.copied}`}
                    src={copySvg}
                    alt="copy-link"
                  />
                  <img
                    className={`${copied ? styles.copied : styles.hidden}`}
                    src={checkSvg}
                    alt="copy-check"
                  />
                </button>
                <button
                  className={styles.iconBtn}
                  type="button"
                  onClick={handleQRCodeView}
                >
                  <img
                    className={styles.profileImage}
                    src={qrCodeSvg}
                    alt="qr-code"
                  />
                </button>
              </div>
            </div>
          </div>
          <div className={styles.appointment}>
            <div className={styles.tabs}>
              <button
                className={`${styles.tabItem} ${
                  itemSelected === 1 && styles.itemSelected
                }`}
                onClick={() => setItemSelected(1)}
              >
                Citas
              </button>
              <button
                className={`${styles.tabItem} ${
                  itemSelected === 2 && styles.itemSelected
                }`}
                onClick={() => setItemSelected(2)}
              >
                Historial
              </button>
            </div>
            <div className={styles.table}>
              {rows.length <= 0 ? (
                <div className={styles.empy}>
                  <span>
                    {itemSelected === 1 && "No hay citas pendientes"}
                    {itemSelected === 2 && "No hay historial de citas"}
                  </span>
                </div>
              ) : (
                rows.map((appointment: Appointment) => (
                  <MyAppointments
                    edit={false}
                    appointment={appointment}
                    onEdit={handleEditAppointment}
                    onDelete={appointments.remove}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </ProtectedRoute>
  );
}
