import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Appointment, initAppointment } from "../../interfaces/Appointment";
import { closeLoading, openLoading } from "../../redux/actions/loading";
import { useDispatch, useSelector } from "react-redux";
import { initServices, Services } from "../../interfaces/Services";
import { Company, initCompany } from "../../interfaces/Company";
import { useEffect, useState } from "react";
import { getMyAppointments } from "../../redux/actions/myAppointment";
import { initStaff, Staff } from "../../interfaces/Staff";
import { RootState } from "../../interfaces/ReduxState";
import { auth } from "../../firebase/config";
import {
  setAppointment,
  updateAppointment,
} from "../../redux/actions/appointment";
import axios from "axios";
import swal from "sweetalert";

import Header from "../../components/Header/Header";
import SelectFecha from "../../components/Appointment/SelectFecha";
import StaffList from "../../components/Appointment/StaffList";
import CompanyHeader from "../../components/CompanyHeader/CompanyHeader";
import ServiceList from "../../components/Appointment/ServicesList/ServicesList";
import EmailForm from "../../components/Appointment/EmailForm/EmailForm";

export interface CompanyData {
  data: Company;
  appointments: Appointment[];
  services: Services[];
  staffs: Staff[];
}

const initCompanyData = (): CompanyData => ({
  data: initCompany(),
  appointments: [],
  services: [],
  staffs: [],
});

const NewAppointment = () => {
  const redirect = useNavigate();
  const dispatch = useDispatch();
  const compannyAppoints = useSelector(
    (state: RootState) => state.appointments
  );
  const myAppointments = useSelector(
    (state: RootState) => state.myAppointments
  );
  const user = useSelector((state: RootState) => state.user);
  const [appoint, setAppoint] = useState<Appointment>(initAppointment());
  const [company, setCompany] = useState<CompanyData>(initCompanyData());
  const [selectFecha, setSelectFecha] = useState<boolean>(false);
  const [selectStaff, setSelectStaff] = useState<boolean>(false);
  const [emailForm, setEmailForm] = useState<boolean>(false);
  const { id: companyId } = useParams();
  const location = useLocation();
  const [serviceSelected, setService] = useState<Services>(initServices());
  const [staffSelected, setStaff] = useState<Staff>(initStaff());
  const [isReady, setIsReady] = useState<boolean>(false);

  // Determine si es modo edición
  const searchParams = new URLSearchParams(location.search);
  const isEdit = searchParams.has("edit");
  const appointmentId = searchParams.get("edit");

  // Load company data, and if is editing get my appointmets
  useEffect(() => {
    handleGetDocs(companyId).then(() => {
      auth.authStateReady().then(() => {
        if (auth.currentUser) {
          if (isEdit && appointmentId) {
            dispatch<any>(getMyAppointments(auth.currentUser.uid));
          }
        }
      });
    });
  }, [companyId, isEdit, appointmentId]);

  // If is editing get appointment and set data
  useEffect(() => {
    if (isEdit && appointmentId) {
      const compannyAppoit = compannyAppoints.find(
        (app) => app.id === appointmentId
      );
      const myAppoint = myAppointments.find((app) => app.id === appointmentId);
      if (compannyAppoit) {
        setAppoint(compannyAppoit);
      } else if (myAppoint) {
        setAppoint(myAppoint);
      }

      setIsReady(!isReady);
      setSelectFecha(true);
    }
  }, [myAppointments]);

  useEffect(() => {
    const service = company.services.find(
      (service) => service.id === appoint.ServiceId
    );
    if (service) setService(service);
    else console.log("Error: Service not found");
  }, [company, isReady]);

  useEffect(() => {
    const staff = company.staffs.find(
      (service) => service.id === appoint.StaffId
    );
    if (staff) setStaff(staff);
    else console.log("Error: Staff not found");
  }, [company, isReady]);

  // Función para obtener datos de la compañía
  async function handleGetDocs(companyId: string | undefined) {
    await axios
      .get(`/company/newAppointment/${companyId}`)
      .then((response) => {
        dispatch(openLoading());
        let data = response.data as CompanyData;

        // Format date
        Object.keys(data.data.hours).forEach((key) => {
          data.data.hours[key as keyof typeof data.data.hours] =
            data.data.hours[key as keyof typeof data.data.hours]?.map(
              (range) => ({
                start: new Date(range.start),
                end: new Date(range.end),
              })
            ) || null;
        });

        data.appointments = data.appointments.map((appoint) => ({
          ...appoint,
          date: new Date(appoint.date!),
        }));

        setCompany(response.data);
        dispatch(closeLoading());
      })
      .catch((error: any) => {
        dispatch(closeLoading());
        console.log(error);
      });
  }

  const handleSubmit = async (email?: string) => {
    if (user.id || (email && typeof email === "string")) {
      dispatch<any>(openLoading());
      const sendEmail =
        email && typeof email === "string" ? email : user.id && user.email;

      (isEdit
        ? dispatch<any>(updateAppointment(appoint))
        : dispatch<any>(setAppointment(company.data.id!, appoint, sendEmail))
      )
        .then(() => {
          dispatch<any>(closeLoading());
          setEmailForm(false);
          swal(
            isEdit ? "Actualizado" : "Agendado",
            `La cita ha sido ${
              isEdit ? "actualizada" : "agendada"
            } exitosamente`,
            "success"
          );
          redirect(isEdit ? "/my-acount" : "/");
        })
        .catch((error: any) => {
          dispatch<any>(closeLoading());
          setEmailForm(false);
          console.log(error);
          swal("Error", "Surgio un error al cargar el turno", "error");
        });
    } else {
      setEmailForm(true);
    }
  };

  const handleLoginFirst = () => {
    localStorage.setItem("appointment", JSON.stringify(appoint));
    redirect(`/login?redirectTo=appointment&company=${companyId!}`);
  };

  const handleSelectStaff = (staff: Staff) => {
    setAppoint({
      ...appoint,
      StaffId: staff.id!,
      staffName: staff.name,
      staffPhoto: staff.photo.url,
    });
    setSelectStaff(false);
    setStaff(staff);
    setSelectFecha(true);
  };

  const handleSelectService = (service: Services) => {
    setAppoint({
      ...appoint,
      ServiceId: service.id!,
      serviceName: service.name,
      description: service.description,
      aclaraciones: service.aclaraciones,
      duration: service.staffDuration,
    });
    setService(service);
    handleOnSelectStaff();
  };

  const onUpdateCompanyAppoints = async (date: Date) => {
    try {
      dispatch(openLoading());
      const appointments = (
        await axios.get(
          `/appointments/availability?companyId=${company.data
            .id!}&date=${date.getTime()}`
        )
      ).data;
      if (!appointments) console.log("Error: appointments not found");
      setCompany({
        ...company,
        appointments: appointments.map((app: Appointment) => ({
          ...app,
          date: new Date(app.date!),
        })),
      });
      dispatch(closeLoading());
    } catch (error) {
      dispatch(closeLoading());
      console.log(error);
      swal("Error", "Error al obtener el historial de citas", "error");
    }
  };

  const handleOnSelectStaff = () => {
    setSelectStaff(!selectStaff);
  };

  const handleCancel = () => {
    swal({
      text: "¿Seguro desea cancelar el turno?",
      buttons: {
        Si: true,
        No: true,
      },
    }).then((response) => response === "Si" && redirect("/"));
  };

  return (
    <div>
      {emailForm && (
        <EmailForm
          onClose={() => setEmailForm(false)}
          onSubmit={handleSubmit}
          onLoginFirst={handleLoginFirst}
        />
      )}
      <CompanyHeader img={company.data.logoImg} name={company.data.name} />
      <Header text={isEdit ? "Editar cita" : "Nueva cita"}>
        {selectStaff && (
          <StaffList
            staffs={company.staffs}
            onSelect={handleSelectStaff}
            onClose={handleOnSelectStaff}
          />
        )}
        {selectFecha !== true ? (
          <ServiceList
            services={company.services}
            onChangeService={handleSelectService}
          />
        ) : (
          <SelectFecha
            edit={!!isEdit}
            data={appoint}
            company={company}
            serviceSelected={serviceSelected}
            staffSelected={staffSelected}
            onChange={setAppoint}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            onSelectService={() => setSelectFecha(false)}
            onSelectStaff={handleOnSelectStaff}
            onUpdateCompanyAppoints={onUpdateCompanyAppoints}
          />
        )}
      </Header>
    </div>
  );
};

export default NewAppointment;
