import { OverlayTrigger, Popover } from "react-bootstrap";
import styles from "./InfoHolder.module.css";

interface Props {
  title: string;
  text?: string;
  html?: string;
}

export default function InfoHolder({ title, text, html }: Props) {
  return (
    <OverlayTrigger
      trigger={["hover", "focus"]}
      placement="right"
      overlay={
        <Popover id="popover-positioned-right" className={styles.popvover}>
          <span className={styles.title}>{title}</span>
          {text && <span>{text}</span>}
          {html && <div dangerouslySetInnerHTML={{ __html: html }} />}
        </Popover>
      }
    >
      <button className={styles.infoButton}>i</button>
    </OverlayTrigger>
  );
}
