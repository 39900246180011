import { StaffConfig } from "./Staff";

export interface Company {
  id?: string;
  name: string;
  category: string;
  logoImg: string;
  location: string;
  state: CompanyState;
  staffConfig: StaffConfig[];
  hours: {
    monday: CompanyHours[] | null;
    tuesday: CompanyHours[] | null;
    wednesday: CompanyHours[] | null;
    thursday: CompanyHours[] | null;
    friday: CompanyHours[] | null;
    saturday: CompanyHours[] | null;
    sunday: CompanyHours[] | null;
  };
}
export interface CompanyError {
  name: string;
  category: string;
  logoImg: string;
  location: string;
}

export interface CompanyHours {
  start: Date;
  end: Date;
}

export enum CompanyState {
  ACTIVE = "Active",
  INACTIVE = "Inactive",
}

export const initCompany = (): Company => ({
  name: "",
  category: "",
  logoImg: "",
  location: "",
  state: CompanyState.ACTIVE,
  staffConfig: [],
  hours: {
    monday: null,
    tuesday: null,
    wednesday: null,
    thursday: null,
    friday: null,
    saturday: null,
    sunday: null,
  },
});

export const initCompanyHours = (): CompanyHours => ({
  start: new Date(),
  end: new Date(),
});

export const initCompanyError = (): CompanyError => ({
  name: "",
  category: "",
  logoImg: "",
  location: "",
});
