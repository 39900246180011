import { useEffect, useState } from "react";
import { Staff as StaffTS } from "../../../interfaces/Staff";
import { useSelector } from "react-redux";
import { useCompany } from "../../../hooks/useCompany";
import { SubsState } from "../../../interfaces/Subscription";
import { RootState } from "../../../interfaces/ReduxState";
import { useStaff } from "../../../hooks/useStaff";
import useSesion from "../../../hooks/useSesion";

import CompanyHeader from "../../../components/CompanyHeader/CompanyHeader";
import StaffForm from "./StaffForm/StaffForm";
import StaffRow from "./StaffRow/StaffRow";
import ProtectedRoute from "../../../components/ProtectedRoute/ProtectedRoute";

import styles from "./Staff.module.css";

export default function Staff() {
  const staffs = useStaff();
  const company = useCompany();
  const user = useSelector((state: RootState) => state.user);
  const [form, setForm] = useState<boolean>(false);
  const { initTest } = useSesion();

  useEffect(() => {
    if (
      user.id &&
      (!user.subscription || user.subscription.state === SubsState.CANCELED)
    ) {
      initTest();
    }
  }, [user]);

  // Get Staff
  useEffect(() => {
    if (company.data?.id && staffs.data.length === 0) {
      staffs.get();
    }
    if (!company.data?.id) {
      company.get();
    }
  }, [company.data.id, user]);

  async function handleSubmit(staff: StaffTS) {
    return staffs.invite(staff);
  }

  const handleForm = () => {
    setForm(!form);
  };

  const handleInvite = async () => {
    setForm(!form);
  };

  return (
    <ProtectedRoute>
      <div className={styles.dashboard}>
        {form && <StaffForm onClose={handleForm} onSubmit={handleSubmit} />}
        <CompanyHeader />
        <div className={styles.container}>
          <h2 className="title-header">Staff</h2>
          <div className={styles.controls}>
            <button
              className="btn btn-outline-primary"
              type="button"
              onClick={handleInvite}
            >
              + Agregar staff
            </button>
          </div>
          <div className={styles.table}>
            <div className={`table-header ${styles.row} ${styles.firstRow}`}>
              <span>#</span>
              <span>Nombre</span>
              <span>E-mail</span>
            </div>
            <div className={styles.body}>
              {staffs.data?.length <= 0 ? (
                <tr className={styles.emptyRows}>
                  <th>No hay servicios</th>
                </tr>
              ) : (
                staffs.data?.map((staff: StaffTS) => (
                  <StaffRow staff={staff} handleDelete={staffs.remove} />
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </ProtectedRoute>
  );
}
