import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { closeLoading, openLoading } from "../../../../redux/actions/loading";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../../redux/actions/profile";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../../interfaces/ReduxState";
import { useState } from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import swal from "sweetalert";

import Input from "../../../../components/Inputs/Input";

import styles from "./CheckoutForm.module.css";

interface Data {
  name: string;
  email: string;
}

interface Error {
  name: string;
  email: string;
  cardNumber: string;
  expirationDate: string;
  securityCode: string;
}

const initData = (): Data => ({
  name: "",
  email: "",
});

const initError = (): Error => ({
  name: "",
  email: "",
  cardNumber: "",
  expirationDate: "",
  securityCode: "",
});
interface Props {
  setLoading: (value: boolean) => void;
}

export default function CheckoutForm({ setLoading }: Props) {
  const dispatch = useDispatch();
  const redirect = useNavigate();
  const stripe = useStripe();
  const elementsUse = useElements();
  const user = useSelector((state: RootState) => state.user);
  const [data, setData] = useState<Data>(initData());
  const [error, setError] = useState<Error>(initError());

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
    setError({ ...error, [name]: "" });
  };

  async function handleSubmit(event: React.FormEvent) {
    event.preventDefault();

    if (handleValidations()) {
      setLoading(true);
      await handleAxios();
      setLoading(false);
    }
  }

  const handleValidations = () => {
    const errors: Error = initError();
    let value = true;

    if (data.name === "") {
      error.name = "Debes completar este campo";
      value = false;
    }

    if (data.email === "") {
      error.email = "Debes completar este campo";
      value = false;
    }

    setError(errors);
    return value;
  };

  const handleAxios = async () => {
    try {
      if (!stripe) throw new Error("Stripe error");

      const cardNumberElement = elementsUse?.getElement(CardNumberElement);

      // Creamos el metodo de pago
      const { paymentMethod, ...response } = await stripe.createPaymentMethod({
        type: "card",
        card: cardNumberElement as any,
        billing_details: {
          name: user.name,
          email: user.email,
        },
      });

      if (response?.error) handleStripeValidations(response.error);
      if (!paymentMethod) throw new Error("Error to create payment method");

      await axios
        .post(`/payment/subscribe/${user.id}`, {
          paymentMethodId: paymentMethod.id,
        })
        .then((response: any) => {
          const subscription = response.data.subscription;
          redirect("/my-acount");
          dispatch(openLoading());
          dispatch<any>(
            updateProfile({
              ...user,
              subscription: {
                ...subscription,
                date: new Date(subscription.date || ""),
              },
            })
          )
            .then(() => dispatch(closeLoading()))
            .catch(() => dispatch(closeLoading()));
        });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleStripeValidations = (error: any) => {
    if (error.code === "incomplete_number") {
      setError({ ...error, cardNumber: "El número estâ incompleto" });
    } else if (error.code === "incomplete_expiry") {
      setError({ ...error, expirationDate: "La fecha esta incompleta" });
    } else if (error.code === "invalid_expiry_year_past") {
      setError({ ...error, expirationDate: "La tarjeta ya esta vencida" });
    } else if (error.code === "incomplete_cvc") {
      setError({ ...error, securityCode: "Clave de seguridad incompleta" });
    } else {
      swal("Error", "Error desconocido", "error");
      console.log(error);
    }
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.inputs}>
        <h5>Datos de la tarjeta</h5>
        <Input
          type="text"
          name="name"
          label="Nombre del Propietario"
          value={data.name}
          error={error.name}
          handleChange={handleChange}
        />
        <Input
          type="email"
          name="email"
          label="Correo Electronico"
          value={data.email}
          error={error.email}
          handleChange={handleChange}
        />
        <div className={styles.input}>
          <span>Numero de la tarjeta</span>
          <CardNumberElement />
          <small>{error.cardNumber}</small>
        </div>
        <h5>Expiración</h5>
        <div className={styles.expiration}>
          <div className={styles.input}>
            <span>Fecha de expiración</span>
            <CardExpiryElement />
            <small>{error.expirationDate}</small>
          </div>
          <div className={styles.input}>
            <span>Número de seguridad</span>
            <CardCvcElement />
            <small>{error.securityCode}</small>
          </div>
        </div>
      </div>
      <div className={styles.checkout}>
        <div className={styles.plan}>
          <span>Plan</span>
          <span>Premium</span>
        </div>
        <hr></hr>
        <div className={styles.price}>
          <span>Total</span>
          <span>€ 9.00</span>
        </div>
        <button className="btn btn-primary" type="submit">
          Suscribirse
        </button>
      </div>
    </form>
  );
}
