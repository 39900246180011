import { useSelector } from "react-redux";
import { RootState } from "../../interfaces/ReduxState";
import { Link } from "react-router-dom";

import LoguedMenu from "./LoguedMenu/LoguedMenu";
import UnloguedMenu from "./UnloguedMenu/UnloguedMenu";

import styles from "./Navbar.module.css";
import logo from "../../assets/img/logo.png";

export default function Navbar() {
  const user = useSelector((state: RootState) => state.user);

  return (
    <nav className={styles.navBar}>
      <div className={styles.content}>
        <Link to={"/"} className={styles.logo}>
          <img src={logo} alt="" />
          <span>WITAR</span>
        </Link>
        {user.id ? <LoguedMenu /> : <UnloguedMenu />}
      </div>
    </nav>
  );
}
