export const generateRandomColor = () => {
  // Generar un valor aleatorio para el tono (hue)
  const hue = Math.floor(Math.random() * 360);
  // Mantener baja la saturación y alta la luminosidad para un color más tenue
  const saturation = 30; // 30% de saturación
  const lightness = 60; // 80% de luminosidad
  const softColor = hslToHex(hue, saturation, lightness);
  return softColor;
};

function hslToHex(h: number, s: number, l: number) {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0");
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}
