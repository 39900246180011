import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { sendPasswordResetEmail } from "firebase/auth";
import { useDispatch } from "react-redux";
import { closeLoading, openLoading } from "../../redux/actions/loading";
import { auth } from "../../firebase/config";
import swal from "sweetalert";

import styles from "./ResetPassword.module.css";
import Input from "../../components/Inputs/Input";
import Header from "../../components/Header/Header";

export default function ResetPassword() {
  const dispatch = useDispatch();
  const redirect = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const correo = /^[\w.@]+$/;

    if (correo.test(event.target.value)) {
      setEmail(event.target.value);
      handleValidation(event.target.value);
    }
  }

  async function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    if (!error) {
      dispatch(openLoading());
      await sendPasswordResetEmail(auth, email)
        .then(() => {
          dispatch(closeLoading());
          swal({
            title: "Enviado",
            text: "Ya se envio un correo para reestablecer la contraseña",
            icon: "success",
            buttons: {
              aceptar: true,
            },
          }).then((res) => {
            redirect("/login");
          });
        })
        .catch((error) => {
          dispatch(closeLoading());
          if (error.message.includes("user-not-found")) {
            setError("No existe un ususario con ese correo");
          } else if (error.message.includes("invalid-email")) {
            setError("El mail no es valido");
          } else {
            swal(
              "Error",
              "Hubo un error al enviar el correo de restauración, intentelo mas tarde",
              "error"
            );
          }
          console.log(error);
        });
    }
  }

  function handleValidation(value: string) {
    const correo = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (value === "") {
      setError("Debes completar el campo");
    } else if (!correo.test(value)) {
      setError("Formato de correo incorrecto");
    } else {
      setError("");
    }
  }

  return (
    <div className={styles.background}>
      <Header text={"Restaurar contraseña"}>
        <form className={`to-left ${styles.form}`} onSubmit={handleSubmit}>
          <div className={styles.container}>
            <Input
              name="email"
              label="Escriba su correo"
              type="email"
              value={email}
              error={error}
              handleChange={handleChange}
            />
            <button className="btn btn-primary" type="submit">
              Enviar
            </button>
            <Link to="/login">{"< Volver"}</Link>
          </div>
        </form>
      </Header>
    </div>
  );
}
