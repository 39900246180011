import { Modal } from "react-bootstrap";
import { Staff } from "../../interfaces/Staff";

import styles from "./ServicesList/ServicesList.module.css";

interface StaffListProps {
  staffs: Staff[];
  onSelect: (staff: Staff) => void;
  onClose: () => void;
}

const StaffList = ({ staffs, onSelect, onClose }: StaffListProps) => {
  return (
    <Modal show={true} onHide={onClose} className="custom-modal">
      <Modal.Body>
        <h3 className="overhead mb-4 ms-2">Elige un profesional</h3>
        {staffs.map((staff) => (
          <div
            key={staff.id}
            onClick={() => onSelect(staff)}
            className={`${styles.button_staffs} btn`}
          >
            <div className="d-flex flex-row justify-content-start align-items-center">
              <div className={styles.imgContainer}>
                {staff.photo.url && (
                  <img
                    src={staff.photo.url}
                    className={styles.img_staff}
                    alt={staff.name}
                  />
                )}
              </div>
              <div className="d-flex  flex-column justify-content-between align-items-start gap-2 ms-3">
                <span className="text-body fw-bold">
                  {staff.name} {staff.surName}
                </span>
                <span className={styles.button_price}></span>
              </div>
            </div>
            <button className="button-celeste btn">Select</button>
          </div>
        ))}
      </Modal.Body>
    </Modal>
  );
};

export default StaffList;
