export interface Subscription {
  companyId: string;
  state: SubsState;
  startDate: Date;
  trialPeriodCompleted: boolean;
}

export enum SubsState {
 TRIAL = "Prueba",
 ACTIVE = "Activo",
 CANCELED = "Cancelado",
 REACTIVATE = "Reactivar",
}