export interface Signin {
  name: string;
  surName: string;
  email: string;
  phone: string;
  password: string;
  repeatPassword?: string;
}

export interface SigninError {
  name: string;
  surName: string;
  email: string;
  phone: string;
  password: string;
  repeatPassword?: string;
}

export const initSignin = (): Signin => ({
  name: "",
  surName: "",
  email: "",
  phone: "",
  password: "",
  repeatPassword: "",
});

export const initSigninError = (): SigninError => ({
  name: "",
  surName: "",
  email: "",
  phone: "",
  password: "",
  repeatPassword: "",
});
