import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Importa los estilos necesarios

import styles from "./TextEditor.module.css";

interface Props {
  label: string;
  value: string;
  onChange: (value: string) => void;
}

const TextEditor = ({ label, value, onChange }: Props) => {
  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["clean"],
    ],
  };

  return (
    <div className={styles.textEditor}>
      <span className={styles.label}>{label}</span>
      <ReactQuill
        className={styles.input}
        value={value}
        onChange={onChange}
        modules={modules}
      />
    </div>
  );
};

export default TextEditor;
