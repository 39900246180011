import PlacesAutocomplete from "./PlaceAutocomplete/PlaceAutocomplete";

import styles from "./MapContainer.module.css";

interface Props {
  location: string;
  error: string;
  setLocation: (adress: string) => void;
}

const MapContainer = ({ location, error, setLocation }: Props) => {
  function middleware(location: string) {
    setLocation(location);
  }

  return (
    <div className={styles.container}>
      <PlacesAutocomplete
        location={location}
        error={error}
        setLocation={middleware}
      />
      <iframe
        title="google_maps"
        className={styles.map}
        src={`https://www.google.com/maps/embed/v1/search?key=${
          process.env.REACT_APP_MAPS_API_KEY
        }&q=${location || "España"}`}
      ></iframe>
    </div>
  );
};
export default MapContainer;
