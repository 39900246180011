import { doc, getDocs, Timestamp, collection } from "firebase/firestore";
import { MyThunkAction, RootState } from "../../../interfaces/ReduxState";
import { Appointment } from "../../../interfaces/Appointment";
import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { auth, db } from "../../../firebase/config";
import { Dispatch } from "react";
import axios from "axios";

export const GET_MY_APPOINTMENTS = "GET_MY_APPOINTMENTS";
export const UPDATE_MY_APPOINTMENT = "UPDATE_MY_APPOINTMENT";

const usersColl = collection(db, "Users");

export function getMyAppointments(
  userId: string
): ThunkAction<Promise<void>, RootState, null, AnyAction> {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      if (!auth.currentUser || !userId)
        throw new Error("the user id not found");

      // Firebase path
      const appointmentsColl = collection(
        doc(usersColl, userId),
        "Appointments"
      );

      // Get data
      const snapshot = await getDocs(appointmentsColl);

      // Save data
      let appointments: any[] = [];
      snapshot.forEach((doc) =>
        appointments.push({
          ...doc.data(),
          id: doc.id,
          date: new Date((doc.data().date as Timestamp)?.toDate()) || null,
        })
      );

      dispatch({
        type: GET_MY_APPOINTMENTS,
        payload: appointments,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function updateMyAppointment(appointment: Appointment): MyThunkAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      await axios.patch("/appointments", {
        appointment,
      });

      dispatch({
        type: UPDATE_MY_APPOINTMENT,
        payload: appointment,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}
