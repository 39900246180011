import { UserState, initUserState } from "../../../interfaces/ReduxState";
import { SET_COMPANY } from "../../actions/company";
import { AnyAction } from "redux";
import {
  GET_USER,
  LOGIN,
  LOGOUT,
  SIGN_IN,
  UPDATE_PROFILE,
} from "../../actions/profile";

export const ProfileReducer = (
  state: UserState = initUserState(),
  action: AnyAction
) => {
  switch (action.type) {
    case SIGN_IN:
      return action.payload;

    case LOGIN:
      return action.payload;

    case LOGOUT:
      return initUserState();

    case UPDATE_PROFILE:
      return { ...state, ...action.payload };

    case SET_COMPANY:
      return {
        ...state,
        CompanyId: action.payload.id,
      };

    case GET_USER:
      return action.payload;

    default:
      return state;
  }
};
