import { forwardRef, useEffect, useState } from "react";
import { CompanyHours } from "../../interfaces/Company";
import { Appointment } from "../../interfaces/Appointment";
import { CompanyData } from "../../pages/NewAppointment/NewAppointment";
import { Services } from "../../interfaces/Services";
import { Staff } from "../../interfaces/Staff";

import DatePicker from "react-datepicker";
import TimeSelector from "./TimeSelector/TimeSelector";
import ImgContainer from "../ImgContainer/ImgContainer";

import styles from "./ServicesList/ServicesList.module.css";
import "react-datepicker/dist/react-datepicker.css";

interface ServiceListProps {
  edit: boolean;
  data: Appointment;
  company: CompanyData;
  serviceSelected: Services;
  staffSelected: Staff;
  onSubmit: () => void;
  onCancel: () => void;
  onChange: (appointment: Appointment) => void;
  onSelectService: () => void;
  onSelectStaff: () => void;
  onUpdateCompanyAppoints: (date: Date) => Promise<void>;
}

interface CustomInputProps {
  value?: Date | null;
  onClick?: () => void;
}

const SelectFecha = ({
  edit,
  data,
  company,
  serviceSelected,
  staffSelected,
  onSubmit,
  onCancel,
  onChange,
  onSelectService,
  onSelectStaff,
  onUpdateCompanyAppoints,
}: ServiceListProps) => {
  const [ranges, setRanges] = useState<CompanyHours[]>([]);
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  useEffect(() => {
    if (data.date && !isNaN(data.date.getTime())) {
      const day = data.date.getDay();
      if (day === 0) setRanges(company.data.hours.sunday || []);
      if (day === 1) setRanges(company.data.hours.monday || []);
      if (day === 2) setRanges(company.data.hours.tuesday || []);
      if (day === 3) setRanges(company.data.hours.wednesday || []);
      if (day === 4) setRanges(company.data.hours.thursday || []);
      if (day === 5) setRanges(company.data.hours.friday || []);
      if (day === 6) setRanges(company.data.hours.saturday || []);
    }
  }, [data]);

  // Función para verificar si la fecha está disponible
  const isAvailableDay = (date: Date) => {
    const day = date.getDay();
    const dayName = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ][day];
    const hours =
      company.data.hours[dayName as keyof typeof company.data.hours];

    return hours !== null && hours.length > 0;
  };

  useEffect(() => {
    let currentDate = new Date(tomorrow); // Empezar a buscar desde mañana
    let counter = 0;

    while (!isAvailableDay(currentDate)) {
      currentDate.setDate(currentDate.getDate() + 1);
      counter++;

      if (counter > 100) {
        break;
      }
    }
    onChange({ ...data, date: currentDate });
  }, []); // Se ejecuta solo una vez al montar el componente

  const ExampleCustomInput = forwardRef(
    (
      { value, onClick }: CustomInputProps,
      ref: React.Ref<HTMLButtonElement>
    ) => (
      <button className="button-white btn" onClick={onClick} ref={ref}>
        Seleccionar
      </button>
    )
  );

  const handleFechaSelect = (date: Date | null) => {
    // Only save if is valid date
    if (date && !isNaN(date.getTime())) {
      onChange({ ...data, date: date });
      onUpdateCompanyAppoints(date);
    }
  };

  return (
    <div className="d-flex flex-column p-10 w-100 justify-content-start">
      <div className={`${styles.fechaContainer} mb-5`}>
        <div className={`${styles.button_services} btn`}>
          <div className="flex-grow-1 d-flex flex-row justify-content-start w-50 align-items-center">
            <div className="d-flex flex-column justify-content-between align-items-start gap-2">
              <span className="text-body fw-bold">{serviceSelected.name}</span>
              <span className={styles.button_price}>
                {serviceSelected.price}€
              </span>
            </div>
          </div>
          <button onClick={onSelectService} className="button-white btn">
            Editar
          </button>
        </div>
        <div className={`${styles.button_services} btn`}>
          <div className="flex-grow-1 d-flex flex-row justify-content-start w-50 align-items-center">
            <div className="d-flex gap-3 align-items-center">
              <ImgContainer url={staffSelected.photo.url} />
              <div className="d-flex flex-column justify-content-between align-items-start gap-2">
                <span className="text-body fw-bold">
                  {staffSelected.name} {staffSelected.surName}
                </span>
                <span className={styles.button_price}>
                  Precencial ({serviceSelected.clientDuration} min)
                </span>
              </div>
            </div>
          </div>
          <button onClick={onSelectStaff} className="button-white btn">
            Editar
          </button>
        </div>
        <div className={`${styles.button_services} btn calendarPick`}>
          <div className="flex-grow-1 d-flex flex-row justify-content-start w-50 align-items-center">
            <div className="d-flex flex-column justify-content-between align-items-start gap-2">
              <span className="text-body fw-bold">Fecha</span>
              <span className={styles.button_price}>
                {data.date ? data.date.toLocaleDateString() : "DD/MM/AAAA"}
              </span>
            </div>
          </div>
          <DatePicker
            selected={data.date}
            onChange={handleFechaSelect}
            minDate={tomorrow}
            filterDate={isAvailableDay}
            customInput={<ExampleCustomInput />}
          />
        </div>
        <TimeSelector
          data={data}
          onChange={onChange}
          appointments={company.appointments}
          ranges={ranges}
          staffDuration={serviceSelected.staffDuration}
          clientDuration={serviceSelected.clientDuration}
          dateSelected={data.date}
        />
      </div>
      <div className="d-flex flex-row w-100 align-items-center justify-content-end gap-4">
        <button
          className="button-white btn px-4 border-celeste cursor-pointer button-large"
          onClick={onCancel}
        >
          Cancelar
        </button>
        <button
          className="button-celeste btn px-4 button-large"
          onClick={onSubmit}
        >
          {edit ? "Actualizar" : "Crear"}
        </button>
      </div>
    </div>
  );
};

export default SelectFecha;
