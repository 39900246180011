import { useDispatch, useSelector } from "react-redux";
import { closeLoading, openLoading } from "../../redux/actions/loading";
import { useNavigate } from "react-router-dom";
import { changeEmail } from "../../redux/actions/profile";
import { RootState } from "../../interfaces/ReduxState";
import { useState } from "react";
import swal from "sweetalert";

import Input from "../../components/Inputs/Input";
import Header from "../../components/Header/Header";

import styles from "./ChangeEmail.module.css";

interface Data {
  email: string;
  password: string;
}

interface DataError {
  email: string;
  password: string;
}

const intData = (): Data => ({
  email: "",
  password: "",
});

const initDataError = (): DataError => ({
  email: "",
  password: "",
});

export default function ChangeEmail() {
  const dispatch = useDispatch();
  const redirect = useNavigate();
  const user = useSelector((state: RootState) => state.user);
  const [data, setData] = useState<Data>(intData());
  const [error, setError] = useState<DataError>(initDataError());

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    const name = event.target.name;
    const correo = /^[\w.@]+$/;

    if (name === "email") {
      if (correo.test(value)) {
        setData({ ...data, email: value });
        setError({ ...error, email: "" });
      }
    } else {
      setData({ ...data, [name]: value });
      setError({ ...error, [name]: "" });
    }
  }

  async function handleSubmitEmail(event: React.FormEvent) {
    event.preventDefault();

    if (handleValidation()) {
      dispatch(openLoading());
      dispatch<any>(changeEmail(data.email, data.password, user))
        .then(() => {
          dispatch(closeLoading());
          swal("Actualizado", "Se cambio el correco con exito", "success").then(
            (res) => {
              if (res) {
                redirect("/dashboard/invoices");
              }
            }
          );
        })
        .catch((error: any) => {
          dispatch(closeLoading());
          if (error.message.includes("requires-recent-login")) {
            swal({
              title: "Error",
              text: "Se requiere que haya iniciado sesion recientemente, reinicie sesión para hacer el cambio",
              icon: "error",
              buttons: {
                iniciar: true,
                cancel: true,
              },
            }).then((res) => {
              if (res) {
                redirect("/login");
              } else {
                redirect("/dashboard/invoices");
              }
            });
          } else {
            swal(
              "Error",
              "Hubo un error al enviar el correo de restauración, intentelo mas tarde",
              "error"
            );
          }
          console.log(error);
        });
    }
  }

  function handleValidation() {
    const correo = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    let errors: DataError = initDataError();
    let value: boolean = true;

    if (data.email === "") {
      error.email = "Debes completar el campo";
      value = false;
    } else if (!correo.test(data.email)) {
      error.email = "Formato de correo incorrecto";
      value = false;
    }

    if (data.password === "") {
      errors.password = "Debes completar este campo";
      value = false;
    }

    setError(errors);
    return value;
  }

  function handleBack() {
    redirect(-1);
  }

  return (
    <div className={styles.background}>
      <Header text="Cambiar correo">
        <form className={styles.form} onSubmit={handleSubmitEmail}>
          <Input
            name="email"
            label="Correo"
            value={data.email}
            error={error.email}
            handleChange={handleChange}
          />
          <Input
            name="password"
            type="password"
            label="Contraseña"
            value={data.password}
            error={error.password}
            handleChange={handleChange}
          />
          <button className="btn btn-primary" type="submit">
            cambiar
          </button>
          <button className="btn btn-outline-primary" onClick={handleBack}>
             Volver
          </button>
        </form>
      </Header>
    </div>
  );
}
