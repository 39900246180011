import { Services } from "../../../interfaces/Services";
import InfoHolder from "../../InfoHolder/InfoHolder";

import styles from "./ServicesList.module.css";

interface ServiceListProps {
  services: Services[];
  onChangeService: (services: Services) => void;
}

const ServiceList = ({ services, onChangeService }: ServiceListProps) => {
  return (
    <div className={styles.container}>
      {services.map((service: Services) => (
        <div key={service.id} className={`${styles.button_services} btn`}>
          <div className="flex-grow-1 d-flex flex-column justify-content-between align-items-start gap-2">
            <span className="text-body fw-bold">{service.name}</span>
            <span className={styles.button_price}>{service.price}€</span>
          </div>
          <button
            className="button-celeste btn"
            onClick={() => onChangeService(service)}
          >
            Select
          </button>
          <InfoHolder title="Descripción:" html={service.description} />
        </div>
      ))}
    </div>
  );
};

export default ServiceList;
