import { Link } from "react-router-dom";

import styles from "./UnloguedMenu.module.css";

export default function UnloguedMenu() {
  return (
    <div className={styles.sesion}>
      <Link to="/">Inicio</Link>
      <Link to="/pricing">Servicios</Link>
      <Link to="/signup" className="btn btn-outline-primary" type="button">
        Registrarse
      </Link>
      <Link to="/login" className="btn btn-primary" type="button">
        Iniciar sesión
      </Link>
    </div>
  );
}
