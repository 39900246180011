import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase/config";
import Loading from "../Loading/Loading";

interface Props {
  children: React.ReactNode;
}

function ProtectedRoute({ children }: Props) {
  const redirect = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [authorized, setAuthorized] = useState<boolean>(false);

  useEffect(() => {
    auth.authStateReady().then(() => {
      if (!auth.currentUser) redirect("/");
      else {
        setLoading(false);
        setAuthorized(true);
      }
    });
  }, []);

  return (
    <div>
      {(loading && <Loading />) || (!loading && authorized && children)};
    </div>
  );
}

export default ProtectedRoute;
