import { generateRandomColor } from "../../../../scripts/generateRandomColor";
import { initStaff, Staff } from "../../../../interfaces/Staff";
import { useEffect, useState } from "react";
import swal from "sweetalert";

import Input from "../../../../components/Inputs/Input";

import styles from "./StaffForm.module.css";
export interface Props {
  onClose: () => void;
  onSubmit: (staff: Staff) => Promise<void>;
}

export default function StaffForm({ onClose, onSubmit }: Props) {
  const [staff, setStaff] = useState<Staff>(initStaff());
  const [error, setError] = useState<string>("");

  useEffect(() => {
    setStaff({ ...staff, email: "", color: generateRandomColor() });
  }, []);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setStaff({ ...staff, [event.target.name]: event.target.value });
  }

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (staff.email === "") {
      setError("Debes completar este campo");
    } else if (!emailRegex.test(staff.email!)) {
      setError("Formato incorrecto");
    } else {
      setError("");
      onSubmit(staff)
        .then(() => {
          setStaff(initStaff());
          onClose();
          swal(
            "Enviado",
            "Ya se ha enviado un correo de invitacion",
            "success"
          );
        })
        .catch((error: any) => {
          if (error?.message.includes("the user is already invited")) {
            setError("Ya cuenta con otra invitacion pendiente");
          } else if (
            error?.message.includes("user already belong to the company")
          ) {
            setError("Ya esta a la compañia");
          } else if (
            error?.message.includes(
              "The user with this email is already part of another company"
            )
          ) {
            setError("Ya esta otra compañia");
          } else {
            console.log(error);
            swal(
              "Error",
              "Hubo un error y no se pudo enviar el correo",
              "error"
            );
          }
        });
    }
  }

  return (
    <div className={styles.background}>
      <div
        className={styles.container}
        data-aos="fade-up"
        data-aos-duration="500"
        data-aos-once="true"
      >
        <header className={styles.header}>
          <h5 className="title-header">Invitar a un usuario</h5>
          <button className="btn btn-close" onClick={onClose} />
        </header>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.inputs}>
            <input
              className={styles.colorSelector}
              name="color"
              type="color"
              value={staff.color}
              onChange={handleChange}
            />
            <Input
              name="email"
              label="Correo"
              value={staff.email}
              error={error}
              handleChange={handleChange}
            />
          </div>
          <div className={styles.btnContainer}>
            <button className="btn btn-primary" type="submit">
              Invitar
            </button>
            <button
              className="btn btn-outline-primary"
              type="button"
              onClick={onClose}
            >
              Cancelar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
