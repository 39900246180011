import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../interfaces/ReduxState";
import {
  ServiceModality,
  ServiceType,
  Services,
  ServicesError,
  initServices,
  initServicesError,
} from "../../../../interfaces/Services";

import Input from "../../../../components/Inputs/Input";
import SelectInput from "../../../../components/Inputs/SelectInput";

import styles from "./ServicesForm.module.css";
import SelectInputId from "../../../../components/Inputs/SelectInputId";
import TextEditor from "../../../../components/TextEditor/TextEditor";

export interface Props {
  data: Services | null;
  handleClose: () => void;
  handleSubmit: (services: Services) => void;
}

export default function ServicesForm({
  data,
  handleClose,
  handleSubmit,
}: Props) {
  const staff = useSelector((state: RootState) => state.staff);
  const [services, setServices] = useState<Services>(initServices());
  const [error, setError] = useState<ServicesError>(initServicesError());

  // If data is selected for editing, update product local data
  useEffect(() => {
    if (data) setServices(data);
  }, [data]);

  // Change product
  function handleChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) {
    setServices({ ...services, [event.target.name]: event.target.value });
    setError({ ...error, [event.target.name]: "" });
  }

  // Sbubmit data
  function handleLocalSubmit(event: React.FormEvent) {
    event.preventDefault();

    if (validations()) {
      handleSubmit(services);
      handleClose();
    }
  }

  function handleChangeDescription(value: string) {
    setServices({ ...services, description: value });
  }

  function handleChangeAclaraciones(value: string) {
    setServices({ ...services, aclaraciones: value });
  }

  // Errors validations
  function validations() {
    let errors: ServicesError = initServicesError();
    let value = true;

    /* NAME */
    if (services.name === "") {
      errors.name = "This field can not be blank";
      value = false;
    }

    /* EMAIL */
    if (services.price === 0) {
      errors.price = "This field can not be blank";
      value = false;
    }

    /* SERVICE MODALITY */
    if (!data && services.modality === ServiceModality.ANY) {
      errors.modality = "This field can not be blank";
      value = false;
    }

    /* CLIENT DURATION */
    if (!data && services.clientDuration === 0) {
      errors.clientDuration = "This field can not be blank";
      value = false;
    }

    /* SAFF DURATION */
    if (!data && services.staffDuration === 0) {
      errors.staffDuration = "This field can not be blank";
      value = false;
    }

    setError(errors);
    return value;
  }

  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <header className={styles.header}>
          <span className="back-to-table" onClick={handleClose}>
            {"< Volver al listado"}
          </span>
          <h3 className="title-header">Crear Servicio</h3>
        </header>
        <form className={styles.form} onSubmit={handleLocalSubmit}>
          <div className={styles.inputsContainer}>
            <Input
              name="name"
              label="Nombre del servicio"
              value={services.name}
              error={error.name}
              handleChange={handleChange}
            />
            <Input
              name="price"
              label="Precio total"
              value={services.price}
              error={error.price}
              handleChange={handleChange}
            />
            <SelectInput
              name="modality"
              label="Modalidad de presentación"
              value={services.modality}
              list={[ServiceModality.PRESENCIAL, ServiceModality.VIRTUAL]}
              error={error.modality}
              handleChange={handleChange}
            />

            <Input
              name="clientDuration"
              label="Duración para el cliente (min)"
              type="number"
              value={services.clientDuration}
              error={error.clientDuration}
              handleChange={handleChange}
            />
            <Input
              name="staffDuration"
              label="Tiempo que ocupa en total (min)"
              type="number"
              value={services.staffDuration}
              error={error.staffDuration}
              handleChange={handleChange}
            />
            <SelectInput
              name="type"
              label="Tipo de servicio"
              value={services.type}
              list={[ServiceType.UNICA_VEZ, ServiceType.RECURRENTE]}
              error={error.type}
              handleChange={handleChange}
            />
            <SelectInputId
              name="StaffId"
              label="¿Quien prestará el servicio?"
              value={services.StaffId}
              error={error.StaffId}
              list={[{ id: "Varios", label: "Varios" }].concat(
                staff
                  .map((s) => ({ id: s.id!, label: s.name }))
                  .sort((a, b) => a.label.localeCompare(b.label))
              )}
              handleChange={handleChange}
            />
            <TextEditor
              label="Descripción"
              value={services.description}
              onChange={handleChangeDescription}
            />
            <TextEditor
              label="Aclaraciones"
              value={services.aclaraciones}
              onChange={handleChangeAclaraciones}
            />
          </div>
          <div className={styles.btnContainer}>
            <button
              className="btn btn-outline-primary"
              type="button"
              onClick={handleClose}
            >
              Cancelar
            </button>
            <button className="btn btn-primary" type="submit">
              {data ? "Editar" : "Crear"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
