import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { connectStorageEmulator, getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAl1hu7hR_8WqrVKJ0vI_szpLmJNdbcuBs",
  authDomain: "witar-fa703.firebaseapp.com",
  projectId: "witar-fa703",
  storageBucket: "witar-fa703.appspot.com",
  messagingSenderId: "105800231091",
  appId: "1:105800231091:web:6b2267576b504f484428c6",
  measurementId: "G-HJ9PFBDS0S"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Initilize FireStore Services
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);

// Emulator
if (process.env.REACT_APP_EMULATOR === "true") {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(db, "localhost", 8090);
  connectStorageEmulator(storage, "localhost", 9199);
}
