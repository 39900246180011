import QRCode from "qrcode.react";

import styles from "./QRCodeView.module.css";

interface Props {
  companyName: string;
  link: string;
  handleClose: () => void;
}

export default function QRCodeView({ companyName, link, handleClose }: Props) {
  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <header className={styles.header}>
          <h3>{companyName}</h3>
          <div className="btn btn-close" onClick={handleClose} />
        </header>
        <QRCode value={link} size={300} />
      </div>
    </div>
  );
}
