import styles from "./TermsAndConditions.module.css";

export default function TermsAndConditions() {
  return (
    <div className={styles.container}>
      <h1>Política de privacidad de Witar</h1>
      <p>
        Esta Política de Privacidad ha sido creada para darle confianza al
        utilizar nuestro sitio web (witar.es), nuestro software ("Software"),
        así como cualquier otro servicio relacionado proporcionado por nosotros
        (en conjunto, el "Servicio"). Todas las referencias a "nosotros",
        "nuestro" o "Witar" se refieren a Witar, S.L. y todas las referencias a
        "usted" o "su" se refieren a las personas que utilizan nuestro Servicio.
        El software ayuda a los usuarios a reservar citas con profesionales,
        ofrecer un servicio que requiere de un turno tanto presencial como
        virtual.
      </p>

      <p>
        Al utilizar el Servicio, usted acepta esta Política de Privacidad. Esta
        política de privacidad se aplica únicamente a la recogida de información
        en o a través del Servicio. No se aplica a la información que
        recopilamos por otros medios (incluso fuera de línea) o de otras fuentes
        que no sean el Servicio.
      </p>

      <h3>1. Información que recogemos</h3>

      <p>
        <strong>Información que nos proporciona:</strong> Para crear una cuenta
        en los Servicios o para habilitar determinadas funciones, necesitamos
        que nos proporcione información ("Datos personales") para su cuenta,
        como nombre, correo electrónico, contraseña y credenciales de
        autenticación.
      </p>

      <h3>Información recopilada automáticamente al utilizar el servicio</h3>

      <p>
        Podemos recopilar directamente datos analíticos o utilizar herramientas
        analíticas de terceros (como las de Google Analytics, Crisp, Hotjar)
        para ayudarnos a medir el tráfico y las tendencias de uso del Servicio.
        Estas herramientas recopilan información enviada por su navegador o
        dispositivo móvil, incluidas las páginas que visita y otra información
        que nos ayuda a mejorar el Servicio. Recopilamos y utilizamos esta
        información analítica de forma agregada, de modo que no pueda
        manipularse razonablemente para identificar a ningún usuario en
        particular. Para obtener más información sobre estas cookies, consulte{" "}
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
        >
          aquí
        </a>
        .
      </p>

      <p>
        Señales Do Not Track y mecanismos similares. No respondemos a las
        señales Do Not Track iniciadas por los navegadores, ya que la industria
        de Internet sigue trabajando en las normas, implementaciones y
        soluciones Do Not Track.
      </p>

      <p>
        <strong>Cookies y tecnologías similares:</strong> Una cookie es un
        pequeño archivo de texto que se puede almacenar en su dispositivo y al
        que se puede acceder desde él cuando visita y utiliza nuestro Servicio.
        Utilizamos cookies o tecnologías similares (incluidas las cookies de
        terceros) para recordar sus preferencias, entender cómo interactúa con
        nuestros Servicios o los correos electrónicos que le enviamos, mantener
        la seguridad de nuestros Servicios y administrar, mejorar y promocionar
        nuestros Servicios...
      </p>

      <h4>Cookies de primera parte</h4>

      <p>
        Utilizamos nuestras cookies con fines de seguridad para personalizar su
        experiencia con nuestro Servicio, mejorar nuestro Servicio y adaptar
        nuestros mensajes. A continuación, se muestran algunos ejemplos de cómo
        utilizamos las cookies de origen.
      </p>

      <ul>
        <li>
          <strong>Datos de registro y de dispositivos:</strong> estas cookies
          nos ayudan a determinar qué tipo de dispositivos utilizas, para saber
          a qué entornos debemos dar prioridad a la hora de crear funciones y
          flujos de trabajo.
        </li>
        <li>
          <strong>Datos analíticos:</strong> Estas cookies nos ayudan a entender
          cómo utiliza el Servicio para que podamos mejorar el Servicio.
        </li>
        <li>
          <strong>Datos de seguridad:</strong> Estas cookies nos ayudan a
          determinar si se debe cerrar la sesión inactiva, en qué dispositivos
          se ha iniciado la sesión y desde dónde.
        </li>
      </ul>

      <h3>2. Cómo utilizamos su información</h3>

      <p>Utilizamos su información de las siguientes maneras:</p>

      <ul>
        <li>Proporcionar, mantener, analizar y mejorar nuestros Servicios.</li>
        <li>
          Ponernos en contacto con usted en relación con nuestros productos,
          servicios y otra información que consideremos que puede ser de su
          interés...
        </li>
        <li>
          Mantener nuestros servicios seguros y protegerlos contra el fraude, el
          abuso y la intrusión.
        </li>
        <li>
          Proporcionar al usuario la asistencia, la información y los servicios
          que solicite.
        </li>
        <li>
          Hacer cumplir los acuerdos legales, incluidas nuestras Condiciones de
          servicio y esta Política de privacidad.
        </li>
        <li>
          Para enviar notificaciones importantes sobre la cuenta o la seguridad.
        </li>
        <li>Para otros fines con su consentimiento.</li>
      </ul>

      <h3>3. Comunicaciones comerciales y de marketing</h3>

      <p>
        Podemos utilizar la información que recogemos o recibimos para
        comunicarnos directa e indirectamente con usted...
      </p>

      <h3>4. Cómo compartimos su información</h3>

      <p>
        Podemos compartir información fuera de Witar solo como se describe a
        continuación:
      </p>

      <ul>
        <li>
          <strong>Terceros de confianza:</strong> Revelamos información a
          nuestros proveedores de servicios o a otros terceros, según sea
          necesario para ayudarnos a llevar a cabo nuestro negocio.
        </li>
        <li>
          <strong>Por motivos legales:</strong> Podemos divulgar su información
          si creemos que es necesario para cumplir con la ley...
        </li>
        <li>
          <strong>Venta de la empresa o de los activos:</strong> Si Witar
          participa en una fusión, adquisición, oferta pública, venta de
          activos, insolvencia, quiebra o un cambio similar en nuestra
          estructura empresarial, es posible que tengamos que revelar su
          información a quienes participan en la transacción, con sujeción a los
          requisitos de confidencialidad.
        </li>
        <li>
          <strong>Consentimiento:</strong> También podemos revelar su
          información a terceros con su consentimiento para hacerlo...
        </li>
      </ul>

      <h3>5. Almacenamiento de la información</h3>

      <p>
        <strong>Almacenamiento:</strong> Witar puede transferir Datos Personales
        a destinatarios ubicados en los Estados Unidos de América...
      </p>

      <p>
        <strong>
          Almacenaremos sus Datos Personales durante el tiempo que sea necesario
          para prestar el Servicio...
        </strong>
      </p>

      <h4>Protección de datos</h4>

      <p>
        Nos preocupamos por la seguridad de su información, y utilizamos una
        variedad de salvaguardias físicas, administrativas y técnicas...
      </p>

      <h3>6. Sus opciones</h3>

      <p>
        <strong>
          Puede modificar algunos de sus Datos Personales a través de la
          configuración de la cuenta que se ofrece en el Servicio...
        </strong>
      </p>

      <h3>7. Usuarios de California</h3>

      <p>
        La Ley de Privacidad del Consumidor de California (CCPA) otorga derechos
        adicionales de privacidad a los consumidores de California...
      </p>

      <h3>8. Enlaces a otros sitios web y servicios</h3>

      <p>
        El Servicio puede contener enlaces a sitios web y servicios de terceros.
        No somos responsables de las prácticas empleadas por estos sitios web o
        servicios...
      </p>

      <h3>
        9. Los servicios y prácticas de terceros están fuera de nuestro control
      </h3>

      <p>
        El Servicio puede utilizar numerosos servicios de terceros como parte de
        la funcionalidad del Servicio...
      </p>

      <h3>10. Cómo contactar con nosotros</h3>

      <p>
        Por favor, póngase en contacto con nosotros en{" "}
        <a href="mailto:privacy@witar.es">privacy@witar.es</a> si tiene alguna
        pregunta sobre la información que podamos haber recopilado sobre
        usted...
      </p>

      <h4>11. Cambios en esta política</h4>

      <p>
        Witar se reserva el derecho a modificar esta política para adaptarla a
        las novedades normativas, jurisprudenciales y a la práctica industrial
        y/o comercial...
      </p>

      <p>
        Si Witar decide cambiar su Política de Privacidad, publicará dichos
        cambios en esta página. Esta política de privacidad fue modificada por
        última vez el 18/10/2023.
      </p>
    </div>
  );
}
