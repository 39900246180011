import { Services as ServicesTS } from "../../../interfaces/Services";
import { useEffect, useState } from "react";
import { useServices } from "../../../hooks/useServices";
import { useSelector } from "react-redux";
import { useCompany } from "../../../hooks/useCompany";
import { RootState } from "../../../interfaces/ReduxState";
import { SubsState } from "../../../interfaces/Subscription";
import useSesion from "../../../hooks/useSesion";

import ServicesRow from "./ServicesRow/ServicesRow";
import ServicesForm from "./ServicesForm/ServicesForm";
import CompanyHeader from "../../../components/CompanyHeader/CompanyHeader";
import ProtectedRoute from "../../../components/ProtectedRoute/ProtectedRoute";

import styles from "./Services.module.css";

export default function Services() {
  const services = useServices();
  const company = useCompany();
  const user = useSelector((state: RootState) => state.user);
  const [form, setForm] = useState<boolean>(false);
  const [data, setData] = useState<ServicesTS | null>(null);
  const { initTest } = useSesion();

  useEffect(() => {
    if (
      user.id &&
      (!user.subscription || user.subscription.state === SubsState.CANCELED)
    ) {
      initTest();
    }
  }, [user]);

  // Get services
  useEffect(() => {
    if (company.data.id && services.data.length <= 0) services.get();
    if (user.CompanyId && !company.data.id) company.get();
  }, [company.data, user]);

  // View form and edit services
  function handleEdit(data: ServicesTS) {
    setData(data);
    handleForm();
  }

  // Alternate form
  function handleForm() {
    setForm(!form);
    if (form) setData(null);
  }

  // Submit data
  function handleSubmit(service: ServicesTS) {
    data ? services.update(service) : services.set(service);
  }

  return (
    <ProtectedRoute>
      <div className={styles.dashboard}>
        <CompanyHeader />
        {form && (
          <ServicesForm
            data={data}
            handleClose={handleForm}
            handleSubmit={handleSubmit}
          />
        )}
        <div className={styles.container}>
          <h2 className="title-header">Servicios</h2>
          <div className={styles.controls}>
            <button
              className="btn btn-outline-primary"
              type="button"
              onClick={handleForm}
            >
              + Agregar servicio
            </button>
          </div>
          <div className={styles.table}>
            <div className={`table-header ${styles.row} ${styles.firstRow}`}>
              <span>Nombre del servicio</span>
              <span>Modalidad</span>
              <span>Tipo de servicio</span>
              <span>Precio total</span>
              <span>Staff</span>
            </div>
            <div className={styles.body}>
              {services.data?.length <= 0 ? (
                <tr className={styles.emptyRows}>
                  <th>No hay servicios</th>
                </tr>
              ) : (
                services.data?.map((service: ServicesTS) => (
                  <ServicesRow
                    service={service}
                    handleEdit={handleEdit}
                    handleDelete={services.remove}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </ProtectedRoute>
  );
}
