import { useSelector } from "react-redux";
import { RootState } from "../../../../interfaces/ReduxState";
import { Services } from "../../../../interfaces/Services";

import style from "./ServicesRow.module.css";
import editSvg from "../../../../assets/icons/edit.svg";
import removeSvg from "../../../../assets/icons/remove.svg";

interface Props {
  service: Services;
  handleEdit: (service: Services) => void;
  handleDelete: (serviceId: string) => void;
}

export default function ServicesRow({
  service,
  handleEdit,
  handleDelete,
}: Props) {
  const staff = useSelector((state: RootState) => state.staff);

  return (
    <div className={`table-text ${style.row}`}>
      <span>{service.name}</span>
      <span>{service.modality}</span>
      <span>{service.type}</span>
      <span>{service.price} €</span>
      <span>{service.StaffId === "Varios" ? "Varios" : staff.find((stuff) => stuff.id === service.StaffId)?.name || "-"}</span>
      <button
        className="btn btn-outline-danger"
        type="button"
        onClick={() => handleDelete(service.id!)}
      >
        <img src={removeSvg} alt="remove" />
      </button>
      <button
        className="btn btn-outline-primary"
        type="button"
        onClick={() => handleEdit(service)}
      >
        <img src={editSvg} alt="edit" />
      </button>
    </div>
  );
}
