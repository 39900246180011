import { MyThunkAction, RootState } from "../../../interfaces/ReduxState";
import { Appointment } from "../../../interfaces/Appointment";
import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { Dispatch } from "react";
import { Company } from "../../../interfaces/Company";
import { User } from "../../../interfaces/User";
import { db } from "../../../firebase/config";
import {
  Timestamp,
  collection,
  doc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import axios from "axios";

export const SET_APPOINTMENT = "SET_APPOINTMENT";
export const GET_APPOINTMENTS = "GET_APPOINTMENTS";
export const UPDATE_APPOINTMENT = "UPDATE_APPOINTMENT";
export const DELETE_APPOINTMENT = "DELETE_APPOINTMENT";

const companiesColl = collection(db, "Companies");

export function setAppointment(
  companyId: string,
  appointment: Appointment,
  email?: string
): MyThunkAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      // Post new appoint
      await axios.post("/appointments", {
        companyId,
        appointment: { ...appointment, email, companyId },
      });

      dispatch({
        type: SET_APPOINTMENT,
        payload: appointment,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function getAppointments(
  CompanyId: string
): ThunkAction<Promise<void>, RootState, null, AnyAction> {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      if (!CompanyId) throw new Error("Company Id not found");

      // Appointments collection
      const appointmentsColl = collection(
        doc(companiesColl, CompanyId),
        "Appointments"
      );

      // Get data
      const snapshot = await getDocs(appointmentsColl);

      // Save data
      let appointments: any[] = [];
      snapshot.forEach((doc) =>
        appointments.push({ ...doc.data(), id: doc.id })
      );

      // Format date
      appointments = appointments.map((app) => ({
        ...app,
        date: new Date((app.date as Timestamp)?.toDate()) || null,
      }));

      dispatch({
        type: GET_APPOINTMENTS,
        payload: appointments,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function updateAppointment(appointment: Appointment): MyThunkAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      await axios.patch("/appointments", {
        appointment,
      });

      dispatch({
        type: UPDATE_APPOINTMENT,
        payload: appointment,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function deleteAppointment(
  appointmentId: string,
  companyId: string
): MyThunkAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      // Llamada DELETE al backend con el id de la cita
      await axios.delete(`/appointments`, {
        params: {
          appointmentId,
          companyId,
        },
      });

      dispatch({
        type: DELETE_APPOINTMENT,
        payload: appointmentId,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}
