import styles from "./PrivacyPolicy.module.css";

export default function PrivacyPolicy() {
  return (
    <div className={styles.container}>
      <h1>Política de privacidad de Witar</h1>

      <p>
        Esta Política de Privacidad ha sido creada para darle confianza al
        utilizar nuestro sitio web (witar.es), nuestro software ("Software"),
        así como cualquier otro servicio relacionado proporcionado por nosotros
        (en conjunto, el "Servicio"). Todas las referencias a "nosotros",
        "nuestro" o "Witar" se refieren a Witar, S.L. y todas las referencias a
        "usted" o "su" se refieren a las personas que utilizan nuestro Servicio.
      </p>

      <p>
        El software ayuda a los usuarios a reservar citas con profesionales,
        ofrecer un servicio que requiere de un turno tanto presencial como
        virtual.
      </p>

      <p>Al utilizar el Servicio, usted acepta esta Política de Privacidad.</p>

      <h2>1. Información que recogemos</h2>

      <p>Información que nos proporciona:</p>
      <p>
        Para crear una cuenta en los Servicios o para habilitar determinadas
        funciones, necesitamos que nos proporcione información ("Datos
        personales") para su cuenta, como nombre, correo electrónico, contraseña
        y credenciales de autenticación. Si se registra para una suscripción de
        pago, nosotros (o nuestros procesadores de pago) podemos necesitar sus
        datos de facturación, como la información de la tarjeta de crédito, la
        información bancaria y la dirección de facturación.
      </p>

      <p>Información recopilada automáticamente al utilizar el servicio</p>

      <p>
        Podemos recopilar directamente datos analíticos o utilizar herramientas
        analíticas de terceros (como las de Google Analytics, Crisp, Hotjar)
        para ayudarnos a medir el tráfico y las tendencias de uso del Servicio.
      </p>

      <h2>2. Cómo utilizamos su información</h2>

      <p>Utilizamos su información de las siguientes maneras:</p>
      <ul>
        <li>Proporcionar, mantener, analizar y mejorar nuestros Servicios.</li>
        <li>
          Ponernos en contacto con usted en relación con nuestros productos,
          servicios y otra información que consideremos que puede ser de su
          interés. Si no desea recibir dichos mensajes de correo electrónico, se
          le dará la opción de excluirse o de cambiar sus preferencias.
        </li>
        <li>
          Mantener nuestros servicios seguros y protegerlos contra el fraude, el
          abuso y la intrusión.
        </li>
        <li>
          Proporcionar al usuario la asistencia, la información y los servicios
          que solicite.
        </li>
        <li>
          Hacer cumplir los acuerdos legales, incluidas nuestras Condiciones de
          servicio y esta Política de privacidad.
        </li>
        <li>
          Para enviar notificaciones importantes sobre la cuenta o la seguridad.
        </li>
        <li>Para otros fines con su consentimiento.</li>
        <li>
          Si la información se agrega o se desidentifica de modo que ya no pueda
          asociarse razonablemente con una persona identificable, podemos
          utilizarla para cualquier fin legal.
        </li>
      </ul>

      <h2>3. Comunicaciones comerciales y de marketing</h2>

      <p>
        Podemos utilizar la información que recogemos o recibimos para
        comunicarnos directa e indirectamente con usted. Podemos enviarle
        mensajes de correo electrónico con boletines, promociones y ofertas
        especiales relacionadas con el Servicio. Si no desea recibir estos
        mensajes de correo electrónico, tendrá la opción de excluirse o cambiar
        sus preferencias. También utilizamos sus datos para enviarle correos
        electrónicos relacionados con el Servicio (por ejemplo, verificación de
        la cuenta, confirmaciones y recordatorios de compra y facturación,
        cambios/actualizaciones de las funciones del Servicio, avisos técnicos y
        de seguridad). Usted no puede optar por no recibir correos electrónicos
        relacionados con el Servicio.
      </p>

      <h2>4. Cómo compartimos su información</h2>

      <p>
        Podemos compartir información fuera de Witar solo como se describe a
        continuación:
      </p>
      <ul>
        <li>
          <strong>Terceros de confianza:</strong> Revelamos información a
          nuestros proveedores de servicios o a otros terceros, según sea
          necesario para ayudarnos a llevar a cabo nuestro negocio.
        </li>
        <li>
          <strong>Por motivos legales:</strong> Podemos divulgar su información
          si creemos que es necesario para cumplir con la ley, la regulación, un
          proceso legal válido, una solicitud gubernamental ejecutable, para
          prevenir el fraude o una violación de la seguridad, hacer cumplir
          nuestras políticas o acuerdos, o proteger nuestros derechos, propiedad
          o seguridad o los de otros.
        </li>
        <li>
          <strong>Venta de la empresa o de los activos:</strong> Si Witar
          participa en una fusión, adquisición, oferta pública, venta de
          activos, insolvencia, quiebra o un cambio similar en nuestra
          estructura empresarial, es posible que tengamos que revelar su
          información a quienes participan en la transacción, con sujeción a los
          requisitos de confidencialidad.
        </li>
        <li>
          <strong>Consentimiento:</strong> También podemos revelar su
          información a terceros con su consentimiento para hacerlo. Por
          ejemplo, si decide habilitar una integración de terceros, podemos
          compartir la información de la cuenta y/o el contenido de su cuenta,
          pero solo con su autorización cuando habilite o utilice la
          integración.
        </li>
      </ul>

      <h2>5. Almacenamiento de la información</h2>

      <p>
        <strong>Almacenamiento:</strong> Witar puede transferir Datos Personales
        a destinatarios ubicados en los Estados Unidos de América...
      </p>
      <p>
        Almacenaremos sus Datos Personales durante el tiempo que sea necesario
        para prestar el Servicio, sin embargo, es posible que no sepamos si
        usted ha dejado de utilizar el Servicio...
      </p>

      <h2>6. Protección de datos</h2>

      <p>
        <strong>Protección de datos:</strong> Nos preocupamos por la seguridad
        de su información, y utilizamos una variedad de salvaguardias físicas,
        administrativas y técnicas para preservar la integridad y la seguridad
        de la información recogida a través del Servicio...
      </p>
      <p>
        Para proteger su privacidad y seguridad, tomamos medidas para verificar
        su identidad antes de concederle acceso a su cuenta...
      </p>
      <p>
        Usted es responsable de mantener el secreto de la información de su
        cuenta, y de controlar el acceso a sus comunicaciones por correo
        electrónico de nosotros, en todo momento...
      </p>
      <p>
        Sin embargo, no podemos asegurar o garantizar la seguridad de cualquier
        información que nos transmita o garantizar que no se pueda acceder,
        divulgar, alterar o destruir la información del Servicio.
      </p>

      <h2>7. Sus opciones</h2>

      <p>
        <strong>Sus opciones:</strong> Puede modificar algunos de sus Datos
        Personales a través de la configuración de la cuenta que se ofrece en el
        Servicio...
      </p>
      <p>
        Además, si desea acceder, recibir una copia, modificar o eliminar los
        Datos Personales que tenemos sobre usted, puede ponerse en contacto con
        nosotros en privacy@witar.es...
      </p>
      <p>
        Sin embargo, podemos retener su información incluso después de que haya
        optado por la exclusión, si la retención es necesaria para cumplir con
        las obligaciones legales, los requisitos reglamentarios, o para prevenir
        el fraude o el abuso.
      </p>

      <h2>8. Usuarios de California</h2>

      <p>
        <strong>Usuarios de California:</strong> La Ley de Privacidad del
        Consumidor de California (CCPA) otorga derechos adicionales de
        privacidad a los consumidores de California, como el derecho a:
      </p>
      <ul>
        <li>
          <strong>Solicitar información:</strong> Solicitar información sobre
          las categorías o elementos específicos de su información personal que
          recopilamos, utilizamos y revelamos...
        </li>
        <li>
          <strong>Solicitar la eliminación:</strong> Solicitar la eliminación de
          sus datos personales.
        </li>
        <li>
          <strong>No recibir trato discriminatorio:</strong> No recibir un trato
          discriminatorio por el ejercicio de su derecho a la intimidad de la
          CCPA.
        </li>
      </ul>
      <p>
        Para presentar una solicitud, puede enviar un correo electrónico a
        privacy@witar.es...
      </p>
      <p>
        La CCPA también exige información específica para los consumidores de
        California...
      </p>

      <h2>9. Enlaces a otros sitios web y servicios</h2>

      <p>
        <strong>Enlaces a otros sitios web y servicios:</strong> El Servicio
        puede contener enlaces a sitios web y servicios de terceros...
      </p>
      <p>
        No somos responsables de las prácticas empleadas por estos sitios web o
        servicios, incluida la información o el contenido que contienen...
      </p>
      <p>
        Recuerde que cuando utiliza un enlace para ir desde el Servicio a otro
        sitio web, nuestra Política de Privacidad no se aplica a esos sitios web
        o servicios...
      </p>

      <h2>
        10. Los servicios y prácticas de terceros están fuera de nuestro control
      </h2>

      <p>
        <strong>
          Los servicios y prácticas de terceros están fuera de nuestro control:
        </strong>{" "}
        El Servicio puede utilizar numerosos servicios de terceros como parte de
        la funcionalidad del Servicio...
      </p>
      <p>
        No tenemos ningún control sobre dichos terceros, excepto en la medida
        requerida por el GDPR y los SCC...
      </p>
      <p>
        Le recomendamos que revise las prácticas de privacidad de dichos
        terceros.
      </p>

      <h2>11. Cómo contactar con nosotros</h2>

      <p>
        <strong>Cómo contactar con nosotros:</strong> Por favor, póngase en
        contacto con nosotros en privacy@witar.es si tiene alguna pregunta sobre
        la información que podamos haber recopilado sobre usted...
      </p>

      <h2>12. Cambios en esta política</h2>

      <p>
        <strong>Cambios en esta política:</strong> Witar se reserva el derecho a
        modificar esta política para adaptarla a las novedades normativas,
        jurisprudenciales y a la práctica industrial y/o comercial...
      </p>
      <p>
        Si Witar decide cambiar su Política de Privacidad, publicará dichos
        cambios en esta página. Esta política de privacidad fue modificada por
        última vez el 18/10/2023.
      </p>

      <p>
        Esta política de privacidad fue modificada por última vez el 18/10/2023.
      </p>
    </div>
  );
}
