import { useState } from "react";

import Input from "../../Inputs/Input";

import styles from "./EmailForm.module.css";

export interface Props {
  onClose: () => void;
  onSubmit: (email: string) => Promise<void>;
  onLoginFirst: () => void;
}

export default function EmailForm({ onClose, onSubmit, onLoginFirst }: Props) {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value);
  }

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (email === "") {
      setError("Debes completar este campo");
    } else if (!emailRegex.test(email!)) {
      setError("Formato incorrecto");
    } else {
      onSubmit(email);
    }
  }

  return (
    <div className={styles.background}>
      <div
        className={styles.container}
        data-aos="fade-up"
        data-aos-duration="500"
        data-aos-once="true"
      >
        <header className={styles.header}>
          <h5 className="title-header">Ingresa tu correo</h5>
          <button className="btn btn-close" onClick={onClose} />
        </header>
        <form className={styles.form} onSubmit={handleSubmit}>
          <Input
            name="email"
            label="Correo"
            value={email}
            error={error}
            handleChange={handleChange}
          />
          <div className={styles.btnContainer}>
            <button className="btn btn-primary" type="submit">
              Agendar
            </button>
          </div>
          <span className={styles.loginLink}>
            ¿Ya tenes cuenta?{" "}
            <a href="" onClick={onLoginFirst}>
              Inciar sesión
            </a>
          </span>
        </form>
      </div>
    </div>
  );
}
