import { Routes, Route, redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "./interfaces/ReduxState";
import { useEffect } from "react";
import useSesion from "./hooks/useSesion";
import axios from "axios";
import AOS from "aos";

import Navbar from "./components/Navbar/Navbar";
import Login from "./pages/Login/Login";
import SignUp from "./pages/SignUp/SignUp";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Footer from "./components/Footer/Footer";
import Services from "./pages/Dashboard/Services/Services";
import Staff from "./pages/Dashboard/Staff/Staff";
import MyCompany from "./pages/Dashboard/MyCompany/MyCompany";
import MyAcount from "./pages/Dashboard/MyAcount/MyAcount";
import NewAppointment from "./pages/NewAppointment/NewAppointment";
import Loading from "./components/Loading/Loading";
import CompanyForm from "./pages/Dashboard/CompanyForm/CompanyForm";
import Princing from "./pages/Princing/Princing";
import Landing from "./pages/Landing/Landing";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import Contact from "./pages/Contact/Contact";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Stripe from "./pages/Princing/Stripe/Stripe";
import ChangeEmail from "./pages/ChangeEmail/ChangeEmail";
import Invitation from "./pages/Invitation/Invitation";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const sesion = useSesion();
  const loading = useSelector((state: RootState) => state.loading);

  // Init Animate On Scroll Library
  AOS.init({
    duration: 1000,
  });

  // Config server
  axios.defaults.baseURL = process.env.REACT_APP_API;

  useEffect(() => {
    sesion.autoLogin();
  }, []);

  return (
    <div className="App">
      {loading && <Loading />}
      <Navbar />
      <Routes>
        <Route path="/" element={<Landing />} />

        <Route path="/pricing" element={<Princing />} />
        <Route path="/pricing/suscribe" element={<Stripe />} />

        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/contact" element={<Contact />} />
        
        <Route path="/invitation" element={<Invitation />} />
        <Route path="/appointment/:id" element={<NewAppointment />} />

        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/change-email" element={<ChangeEmail />} />

        <Route path="/my-acount" element={<MyAcount />} />
        <Route path="/my-company" element={<MyCompany />} />
        <Route path="/company-form" element={<CompanyForm />} />
        <Route path="/staff" element={<Staff />} />
        <Route path="/services" element={<Services />} />

        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
