import styles from "./CookiesModal.module.css";

interface Props {
  handleAcept: () => void,
  handleDecline: () => void,
} 

export default function CookiesModal({
  handleAcept,
  handleDecline
}: Props) {
  return (
    <div className={styles.background}>
      <form className={styles.form}>
        <p><b>Esta pagina utiliza Cookies</b></p>
        <span>Debes aceptar para poder continuar navegando</span>
        <div className={styles.btnContainer}>
          <button className="btn btn-outline-primary" onClick={handleAcept}>Aceptar</button>
          <button className="btn btn-primary" onClick={handleDecline}>Cancelar</button>
        </div>
      </form>
    </div>
  );
}
