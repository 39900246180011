import { Company, CompanyState } from "../../../interfaces/Company";
import { auth, db, storage } from "../../../firebase/config";
import { MyThunkAction } from "../../../interfaces/ReduxState";
import { AnyAction } from "redux";
import { Dispatch } from "react";
import { User } from "../../../interfaces/User";
import {
  Timestamp,
  collection,
  doc,
  getDoc,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import {
  uploadBytes,
  ref as storageRef,
  getDownloadURL,
} from "firebase/storage";
import { generateRandomColor } from "../../../scripts/generateRandomColor";
import { StaffState } from "../../../interfaces/Staff";

export const SET_COMPANY = "SET_COMPANY";
export const GET_COMPANY = "GET_COMPANY";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const TOGGLE_STATE_COMPANY = "TOGGLE_STATE_COMPANY";

const companiesColl = collection(db, "Companies");

export function setCompany(
  company: Company,
  user: User,
  file: File | null
): MyThunkAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    // Check current user
    if (!auth.currentUser) throw new Error("User not logued");

    // Init batch
    const batch = writeBatch(db);

    // Compnay doc
    const companyDoc = doc(companiesColl);

    let imageUrl = "";
    // If file exist
    if (file) {
      // Save logo
      const dir = `companies/${companyDoc.id}`;
      const storageReference = storageRef(storage, dir);
      const imageQuery = await uploadBytes(storageReference, file);

      // Get invoice image url
      imageUrl = await getDownloadURL(imageQuery.ref);
    }

    // Company data to post
    let newCompany: Company = {
      name: company.name,
      category: company.category,
      logoImg: imageUrl,
      location: company.location,
      state: company.state,
      hours: company.hours,
      staffConfig: [
        {
          userId: auth.currentUser.uid,
          state: StaffState.WORKING,
          email: user.email,
          color: generateRandomColor(),
        },
      ],
    };

    // User data to update
    const updateUser = {
      name: user.name,
      surName: user.surName,
      email: user.email,
      CompanyId: companyDoc.id,
    };

    // Create Company
    batch.set(companyDoc, newCompany);

    // Update user data
    batch.update(
      doc(collection(db, "Users"), auth.currentUser.uid),
      updateUser
    );

    // Add id from company
    newCompany.id = companyDoc.id;

    // Send data
    await batch.commit();

    try {
      dispatch({
        type: SET_COMPANY,
        payload: newCompany,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function getCompany(companyId: string): MyThunkAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      // Get company data
      const snapshot = await getDoc(doc(companiesColl, companyId));

      // Verify if exist
      if (!snapshot.exists()) throw new Error("Company not found");

      // Get data to snapshot
      let company = snapshot.data() as Company;

      const format = (day: any) => {
        return day
          ? day.map((data: any) => ({
              start: (data.start as Timestamp).toDate(),
              end: (data.end as Timestamp).toDate(),
            }))
          : null;
      };

      // Set format
      company = {
        ...company,
        id: snapshot.id,
        hours: {
          monday: format(company.hours.monday),
          tuesday: format(company.hours.tuesday),
          wednesday: format(company.hours.wednesday),
          thursday: format(company.hours.thursday),
          friday: format(company.hours.friday),
          saturday: format(company.hours.saturday),
          sunday: format(company.hours.sunday),
        },
      };

      dispatch({
        type: GET_COMPANY,
        payload: company,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function updateCompany(
  company: Company,
  file: File | null
): MyThunkAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    // Check current user
    if (!auth.currentUser) throw new Error("User not logued");

    let imageUrl = "";

    // If file exist
    if (file) {
      // Save logo
      const dir = `companies/${company.id}`;
      const storageReference = storageRef(storage, dir);
      const imageQuery = await uploadBytes(storageReference, file);

      // Get invoice image url
      imageUrl = await getDownloadURL(imageQuery.ref);
    }

    // Company data to update
    let newCompany: Company = {
      name: company.name,
      category: company.category,
      logoImg: imageUrl || company.logoImg,
      location: company.location,
      state: company.state,
      hours: company.hours,
      staffConfig: company.staffConfig,
    };

    // Update Company
    await updateDoc(doc(companiesColl, company.id), { ...newCompany });

    try {
      dispatch({
        type: UPDATE_COMPANY,
        payload: newCompany,
      });
    } catch (e: any) {
      console.log(e);
      throw new Error(e);
    }
  };
}

export function disabledCompany(company: Company): MyThunkAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    // Update Company
    if (company.state === CompanyState.ACTIVE) {
      await updateDoc(doc(companiesColl, company.id), {
        state: CompanyState.INACTIVE,
      });
    } else if (company.state === CompanyState.INACTIVE) {
      await updateDoc(doc(companiesColl, company.id), {
        state: CompanyState.ACTIVE,
      });
    }

    try {
      dispatch({
        type: TOGGLE_STATE_COMPANY,
        payload: company,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}
