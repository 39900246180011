export interface Invitation {
  id: string;
  email: string;
  CompanyId: string;
}

export const initInvitation = (): Invitation => ({
  id: "",
  email: "",
  CompanyId: "",
});
