import { useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { SubsState } from "../../../interfaces/Subscription";
import { RootState } from "../../../interfaces/ReduxState";
import { Elements } from "@stripe/react-stripe-js";
import { useState } from "react";
import { Link } from "react-router-dom";

import CheckoutForm from "./CheckoutForm/CheckoutForm";
import Loading from "../../../components/Loading/Loading";

import styles from "./Stripe.module.css";

const stripePromise = loadStripe(
  "pk_test_51MXCRHFGcK4r3CMmOwKQfOj6HM3Fza5NLMniFpcYWhAwUKhXoLlnKIoP0sr86LRyX7ujVlMUop4T4Zl6myZcCWyV00HbKbfJU9"
);

export default function Stripe() {
  const [loading, setLoading] = useState(false);
  const user = useSelector((state: RootState) => state.user);

  return (
    <div className={styles.background}>
      {user.subscription?.state === SubsState.ACTIVE ||
      user.subscription?.state === SubsState.TRIAL ? (
        <div className={styles.subsDetails}>
          <span>
            Y estas susbripto al plan <b>Premium</b>
          </span>
          <span>
            Podes verlo en tu <Link to="/my-acount">perfil</Link>.
          </span>
        </div>
      ) : user.subscription?.state === SubsState.REACTIVATE ? (
        <div className={styles.subsDetails}>
          <span>
            Y estas susbripto al plan <b>Premium</b> hasta el final de tu ciclo
          </span>
          <span>
            Podes verlo y reactivar tu plan en tu{" "}
            <Link to="/my-acount">perfil</Link>.
          </span>
        </div>
      ) : (
        <Elements stripe={stripePromise}>
          <CheckoutForm setLoading={setLoading} />
        </Elements>
      )}
      {loading ? <Loading /> : null}
    </div>
  );
}
