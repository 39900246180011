import { useEffect } from "react";
import AOS from "aos";

import Home from "../../components/LandingSeccions/1-Home/Home";
/* import Clients from "./2-Clients/Clients"; */
import AboutSystem from "../../components/LandingSeccions/3-AboutSystem/AboutSystem";
import Questions from "../../components/LandingSeccions/4-Questions/Questions";

export default function Landing() {
  useEffect(() => {
    AOS.init();
  });

  return (
    <div>
      <Home />
      {/*       <Clients /> */}
      <AboutSystem />
      <Questions />
    </div>
  );
}
