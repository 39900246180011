import { AnyAction } from "redux";
import { StaffState, initStaffState } from "../../../interfaces/ReduxState";
import {
  DELETE_STAFF,
  GET_STAFFS,
  SET_STAFF,
  UPDATE_STAFF,
} from "../../actions/staff";

export const StaffReducer = (
  state: StaffState = initStaffState(),
  action: AnyAction
) => {
  switch (action.type) {
    case SET_STAFF:
      return [...state, action.payload];

    case GET_STAFFS:
      return action.payload;

    case UPDATE_STAFF:
      return state.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );

    case DELETE_STAFF:
      return state.filter((staff) => staff.email !== action.payload);

    default:
      return state;
  }
};
