import { CompanyHours } from "../../../interfaces/Company";
import { Appointment } from "../../../interfaces/Appointment";
import { useState } from "react";

import styles from "./TimeSelector.module.css";

interface TimeSlotsProps {
  data: Appointment;
  ranges: CompanyHours[];
  staffDuration: number;
  clientDuration: number;
  appointments: Appointment[];
  dateSelected: Date | null;
  onChange: (data: Appointment) => void;
}

const TimeSelector: React.FC<TimeSlotsProps> = ({
  data,
  ranges,
  staffDuration,
  clientDuration,
  appointments,
  dateSelected,
  onChange,
}) => {
  const [selectedTime, setSelectedTime] = useState<Date | null>(null);
  const [selectedTimeFormated, setSelectedTimeFormated] = useState<string>("");
  const [showSlots, setShowSlots] = useState<boolean>(false);

  const calculateTimeSlots = () => {
    const slots: { time: string; available: boolean }[] = [];

    if (clientDuration > 0 && staffDuration > 0) {
      ranges.forEach((range) => {
        const dateSelected = new Date(data.date!);
        dateSelected.setSeconds(0);
        dateSelected.setMilliseconds(0);

        // Create currentTime
        dateSelected.setHours(range.start.getHours());
        dateSelected.setMinutes(range.start.getMinutes());
        let currentTime = new Date(dateSelected.getTime());

        dateSelected.setHours(range.end.getHours());
        dateSelected.setMinutes(range.end.getMinutes());
        const rangeEnd = new Date(dateSelected.getTime());

        let counter = 0;
        while (currentTime < rangeEnd) {
          if (counter > 1000) break;
          counter++;

          const endTime = new Date(
            currentTime.getTime() + Number(clientDuration) * 60000
          );
          const nextTime = new Date(
            currentTime.getTime() + Number(staffDuration) * 60000
          );
          endTime.setMilliseconds(0);

          if (endTime <= rangeEnd) {
            const available = !isSlotBlocked(currentTime);
            slots.push({
              time: formatTime(currentTime) + "-" + formatTime(endTime),
              available,
            });
            currentTime = nextTime;
          } else {
            break;
          }
        }
      });
    }

    return slots;
  };

  const isSlotBlocked = (startTime: Date): boolean => {
    if (
      data.date &&
      data.date.getTime() < new Date().getTime() &&
      startTime.getTime() < new Date().getTime()
    ) {
      return true;
    }

    if (dateSelected) {
      const endTime = new Date(
        startTime.getTime() + Number(staffDuration) * 60000
      );
      endTime.setMilliseconds(0);

      return appointments.some((appointment) => {
        if (!appointment.date) return false;

        const appointStart = new Date(appointment.date.getTime());
        appointStart.setMilliseconds(0);
        const appointEnd = new Date(
          appointment.date.getTime() + appointment.duration * 60000
        );
        appointEnd.setMilliseconds(0);

        // Establece las partes de segundos y milisegundos a 0 para comparación precisa
        startTime.setSeconds(0);
        startTime.setMilliseconds(0);
        endTime.setSeconds(0);
        endTime.setMilliseconds(0);

        // El "slot" está disponible solo si su fin es antes o igual al inicio de la cita,
        // o si su inicio es después o igual al fin de la cita
        return !(
          endTime.getTime() <= appointStart.getTime() ||
          startTime.getTime() >= appointEnd.getTime()
        );
      });
    }

    return true; // Bloquea el "slot" si no hay fecha seleccionada
  };

  const formatTime = (date: Date): string => {
    return date.toTimeString().slice(0, 5);
  };

  const handleShowDropDown = () => {
    setShowSlots(!showSlots);
  };

  const handleTimeSelect = (slot: string) => {
    const timeParts = slot.split("-")[0].split(":");
    const selected = new Date();
    selected.setHours(Number(timeParts[0]), Number(timeParts[1]), 0);
    setSelectedTimeFormated(`${slot.split("-")[0]} a ${slot.split("-")[1]}`);
    setSelectedTime(selected);
    handleShowDropDown();
    if (data.date && !isNaN(data.date.getTime())) {
      const newDate = new Date(
        data.date.getFullYear(),
        data.date.getMonth(),
        data.date.getDate(),
        Number(slot.split("-")[0].split(":")[0]),
        Number(slot.split("-")[0].split(":")[1]),
        0
      );
      onChange({ ...data, date: newDate });
    }
  };

  return (
    <div className={styles.timeContainer}>
      <div className={styles.selector}>
        <span>{selectedTime ? selectedTimeFormated : "--:-- a --:--"}</span>
        <button className="button-white btn" onClick={handleShowDropDown}>
          Seleccionar
        </button>
      </div>
      {showSlots && (
        <ul className={styles.dropDown}>
          {calculateTimeSlots().map((slot, index) => (
            <li
              key={index}
              className={`${
                slot.available ? styles.available : styles.blocked
              }`}
            >
              <button
                onClick={() => slot.available && handleTimeSelect(slot.time)}
              >
                {slot.time}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default TimeSelector;
