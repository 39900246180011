import { User, UserType, initUser } from "../../../interfaces/User";
import { Subscription, SubsState } from "../../../interfaces/Subscription";
import { MyThunkAction } from "../../../interfaces/ReduxState";
import { LoginData } from "../../../interfaces/Login";
import { AnyAction } from "redux";
import { Dispatch } from "react";
import { auth, db } from "../../../firebase/config";
import { Signin } from "../../../interfaces/Signin";
import {
  Timestamp,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import {
  signOut,
  updateEmail,
  updatePassword,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";

export const SIGN_IN = "SIGN_IN";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const UPDATE_EMAIL = "UPDATE_EMAIL";
export const GET_USER = "GET_USER";

export const SET_PROFILE = "SET_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const DELETE_PROFILE = "DELETE_PROFILE";

const userColl = collection(db, "Users");

export function singUp(user: Signin): MyThunkAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      // Create user
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        user.email,
        user.password!
      );

      // Create data to save
      const userId = userCredential.user.uid;
      let newUser: User = {
        name: user.name,
        surName: user.surName,
        email: user.email,
        type: UserType.USER,
        photo: {
          url: "",
          fileName: "",
        },
      };

      // Chek invitations with this email
      const invitationQuery = query(
        collection(db, "Invitations"),
        where("email", "==", user.email)
      );
      const invitationDoc = await getDocs(invitationQuery);
      if (invitationDoc.size > 0) {
        const invitation = invitationDoc.docs[0].data();
        newUser.InvitationId = invitation.id;
      }

      // Save data
      await setDoc(doc(userColl, userId), newUser);

      // Add id
      newUser.id = userId;

      dispatch({
        type: SIGN_IN,
        payload: newUser,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function logIn(user: LoginData): MyThunkAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      // Login user
      await signInWithEmailAndPassword(auth, user.email, user.password);

      // Get user data
      const snapshot = await getDoc(doc(userColl, auth.currentUser?.uid));

      // Get data and add the id
      const currentUser = {
        ...snapshot.data(),
        id: snapshot.id,
      };

      dispatch({
        type: LOGIN,
        payload: currentUser,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function singUpWithGoogle(): MyThunkAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      // Create the acount with Google
      const provider = new GoogleAuthProvider();
      const response = await signInWithPopup(auth, provider).catch((error) => {
        console.log(error);
      });
      /*
      // Singup with google
      const credential = GoogleAuthProvider.credentialFromResult(response);
      if (!credential) throw new Error("Error to signup with Google");
      if (!response.user.email) throw new Error("Email not found");

      // Create data to save
      const userId = response.user.uid;
      let newUser: User = {
        name: response.user.displayName || "",
        surName: "",
        photo: "",
        email: response.user.email!,
        type: UserType.USER,
      };

      // Save data
      await setDoc(doc(userColl, userId), newUser);

      // Add id
      newUser.id = userId;
*/
      dispatch({
        type: SIGN_IN,
        payload: {},
      });
    } catch (e: any) {
      console.log(e);
      throw new Error(e);
    }
  };
}

export function logInWithGoogle(): MyThunkAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      // Create the acount with Google
      await signInWithPopup(auth, new GoogleAuthProvider()).catch((error) => {
        console.log(error);
      });
      /*

      // Singup with google
      const credential = GoogleAuthProvider.credentialFromResult(response);
      if (!credential) throw new Error("Error to signup with Google");
      if (!response.user.email) throw new Error("Email not found");

      // Get user data
      const userColl = collection(db, "Users");
      const userDoc = doc(userColl, response.user.uid);
      const snapshot = await getDoc(userDoc);

      let userData: User = initUser();

      if (snapshot.exists()) {
        userData = snapshot.data() as User;
        userData.id = snapshot.id;
        if (userData.subscription) {
          userData.subscription.date = (
            snapshot.data()!.subscription.date as Timestamp
          ).toDate();
        }
      }
*/
      dispatch({
        type: LOGIN,
        payload: {},
      });
    } catch (e: any) {
      console.log(e);
      throw new Error(e);
    }
  };
}

export function logOut(): MyThunkAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      await signOut(auth);

      dispatch({
        type: LOGOUT,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function getUser(): MyThunkAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      if (!auth.currentUser) throw new Error("No existe el usuario");

      // Get user data
      const userColl = collection(db, "Users");
      const userDoc = doc(userColl, auth.currentUser.uid);
      const snapshot = await getDoc(userDoc);

      let userData: User = initUser();

      if (snapshot.exists()) {
        userData = snapshot.data() as User;
        userData.id = snapshot.id;
        if (userData.subscription) {
          userData.subscription.date = (
            snapshot.data()!.subscription.date as Timestamp
          ).toDate();
        }
      }

      dispatch({
        type: GET_USER,
        payload: userData,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function changePassword(
  newPassword: string,
  curretnPassword: string,
  user: User
): MyThunkAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      if (!auth.currentUser) throw new Error("No existe el usuario");
      if (!user.email) throw new Error("Email not found");

      await signInWithEmailAndPassword(auth, user.email, curretnPassword);
      await updatePassword(auth.currentUser, newPassword);
      dispatch({
        type: CHANGE_PASSWORD,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function changeEmail(
  newEmail: string,
  curretnPassword: string,
  user: User
): MyThunkAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      if (!auth.currentUser) throw new Error("No existe el usuario");
      if (!user.email) throw new Error("Email not found");

      await signInWithEmailAndPassword(auth, user.email, curretnPassword);
      await updateEmail(auth.currentUser, newEmail);
      const userCol = collection(db, "Users");
      const userDoc = doc(userCol, auth.currentUser.uid);

      await updateDoc(userDoc, { email: newEmail });

      dispatch({
        type: UPDATE_EMAIL,
        payload: newEmail,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function updateProfile(user: User): MyThunkAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      // Check data
      if (!auth.currentUser) throw new Error("No existe el usuario");

      // Firebase path
      const userCol = collection(db, "Users");
      const userDoc = doc(userCol, auth.currentUser.uid);

      await updateDoc(userDoc, { ...user });

      dispatch({
        type: UPDATE_PROFILE,
        payload: user,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}

export function suscribeTest(user: User): MyThunkAction {
  return async (dispatch: Dispatch<AnyAction>) => {
    try {
      // Check data
      if (!auth.currentUser) throw new Error("No existe el usuario");

      // Firebase path
      const userCol = collection(db, "Users");
      const userDoc = doc(userCol, auth.currentUser.uid);
      const subsCol = collection(db, "Subscriptions");
      const subsDoc = doc(subsCol, auth.currentUser.uid);

      const userUpdated: User = {
        ...user,
        subscription: {
          state: SubsState.TRIAL,
          date: new Date(),
          planId: "",
          tested: true,
        },
      };

      const subsUpdate: Subscription = {
        companyId: "",
        state: SubsState.TRIAL,
        startDate: userUpdated.subscription!.date,
        trialPeriodCompleted: false,
      };

      const batch = writeBatch(db);
      batch.update(userDoc, { ...userUpdated });
      batch.set(subsDoc, { ...subsUpdate });
      await batch.commit();

      dispatch({
        type: UPDATE_PROFILE,
        payload: userUpdated,
      });
    } catch (e: any) {
      throw new Error(e);
    }
  };
}
