
import { CompanyHours } from "../../../../../interfaces/Company";
import { dateToTime } from "../../../../../scripts/dateToTime";

import styles from "./HourSelector.module.css";

export interface Props {
  data: CompanyHours;
  onChange: (hour: CompanyHours) => void;
  onAddHour: () => void;
  onRemoveHour: () => void;
}

export default function HourSelector({ data, onChange, onAddHour, onRemoveHour }: Props) {

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    // Get the hour and the minutes
    const timeArray = event.target.value.split(":");

    // Create a new Date with time seletec
    const newDate = new Date();
    newDate.setHours(Number(timeArray[0]));
    newDate.setMinutes(Number(timeArray[1]));

    // Save the data
    onChange({
      ...data,
      [event.target.name]: newDate
    });
  }

  return (
    <div className={styles.container}>
      <input
        name="start"
        type="time"
        placeholder="Hora inicio"
        value={dateToTime(data.start)}
        onChange={handleChange}
      />
      <span>-</span>
      <input
        name="end"
        type="time"
        placeholder="Hora final"
        value={dateToTime(data.end)}
        onChange={handleChange}
      />
      <button className="btn bnt-outline-primary" type="button" onClick={onAddHour}>+</button>
      <button className="btn bnt-outline-primary" type="button" onClick={onRemoveHour}>-</button>
    </div>
  )
}
