import { AnyAction } from "redux";
import {
  DELETE_SERVICE,
  GET_SERVICES,
  SET_SERVICE,
  UPDATE_SERVICE,
} from "../../actions/services";
import {
  ServicesState,
  initServicesState,
} from "../../../interfaces/ReduxState";

export const ServicesReducer = (
  state: ServicesState = initServicesState(),
  action: AnyAction
) => {
  switch (action.type) {
    case SET_SERVICE:
      return [ ...state, action.payload ];

    case GET_SERVICES:
      return action.payload;

    case UPDATE_SERVICE:
      return state.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );

    case DELETE_SERVICE:
      return state.filter((item) => item.id !== action.payload.id)

    default:
      return state;
  }
};
