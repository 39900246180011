import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../interfaces/ReduxState";
import { SubsState } from "../../interfaces/Subscription";
import useSesion from "../../hooks/useSesion";
import swal from "sweetalert";

import styles from "./Princing.module.css";

export default function Princing() {
  const redirect = useNavigate();
  const { initTest } = useSesion();
  const user = useSelector((state: RootState) => state.user);

  const handleSuscription = () => {
    if (!user.id) {
      redirect("/singup");
    } else if (!user.subscription) {
      initTest();
    } else if (
      user.subscription.state === SubsState.ACTIVE ||
      user.subscription.state === SubsState.TRIAL ||
      user.subscription.state === SubsState.CANCELED
    ) {
      redirect("/pricing/suscribe");
    } else {
      swal({
        text: "¿Quieres registrarte para empezar el período de prueba de 30 días?",
        buttons: {
          Registrarme: true,
          No: true,
        },
      }).then((response: string) => {
        if (response === "Registrarme") {
          redirect("/");
        }
      });
    }
  };

  return (
    <div className={styles.pricing}>
      <div className={styles.pricingImg} data-aos="fade-right">
        <img src="" alt="" />
      </div>
      <div className={styles.pricingData} data-aos="fade-left">
        <h2>
          Witar es una plataforma innovadora que permite gestionar turnos de
          manera eficiente y sencilla
        </h2>
        <ul className={styles.listaPricing}>
          <li>
            <img src="/check.svg" alt="check" />
            Acceso desde cualquier dispositivo
          </li>
          <li>
            <img src="/check.svg" alt="check" />
            Prueba gratuita de 30 días
          </li>
          <li>
            <img src="/check.svg" alt="check" />
            Gestión integral del emprendimiento
          </li>
        </ul>
        <div className={styles.price}>
          <span className="number">9€</span>
          <div className={styles.priceDetails}>
            <span className={styles.priceTitle}>
              Una tarifa, todos los beneficios
            </span>
            <span className={styles.priceSubtitle}>Pago realizado por mes</span>
          </div>
        </div>
        <button
          className="btn btn-primary"
          type="button"
          onClick={handleSuscription}
        >
          Suscribirse
        </button>
      </div>
    </div>
  );
}
