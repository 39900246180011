export interface Appointment {
  id?: string;
  date: Date | null;
  serviceName: string;
  description: string;
  aclaraciones: string;
  duration: number;
  staffName: string;
  staffPhoto: string;
  ServiceId: string;
  StaffId: string;
  email: string;
  companyId: string;
}

export interface AppointsLite {
  id: string;
  date: Date;
  duration: number;
}

export interface AppointmentError {
  day: string;
  hour: string;
  ServiceId: string;
  StaffId: string;
}

export const initAppointment = (): Appointment => ({
  date: new Date(),
  serviceName: "",
  description: "",
  aclaraciones: "",
  duration: 0,
  staffName: "",
  staffPhoto: "",
  ServiceId: "",
  StaffId: "",
  email: "",
  companyId: "",
});

export const initAppointmentError = (): AppointmentError => ({
  day: "",
  hour: "",
  ServiceId: "",
  StaffId: "",
});
