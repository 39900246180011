import styles from "./ImgContainer.module.css";

interface Props {
  url: string;
}

export default function ImgContainer({ url }: Props) {
  return (
    <div className={styles.imgContainer}>
      {url && <img src={url} alt="profile" />}
    </div>
  );
}
