export interface Services {
  id?: string;
  name: string;
  price: number;
  type: ServiceType;
  modality: ServiceModality;
  description: string;
  aclaraciones: string;
  clientDuration: number;
  staffDuration: number;
  StaffId: string;
}

export enum ServiceModality {
  PRESENCIAL = "Presencial",
  VIRTUAL = "Virtual",
  ANY = "ANY",
}

export enum ServiceType {
  RECURRENTE = "Recurrente",
  UNICA_VEZ = "Única vez",
}

export interface ServicesError {
  name: string;
  price: string;
  type: string;
  modality: string;
  clientDuration: string;
  staffDuration: string;
  description: string;
  StaffId: string;
}

export const initServices = (): Services => ({
  name: "",
  price: 0,
  type: ServiceType.UNICA_VEZ,
  modality: ServiceModality.ANY,
  description: "",
  aclaraciones: "",
  clientDuration: 0,
  staffDuration: 0,
  StaffId: "Varios",
});

export const initServicesError = (): ServicesError => ({
  name: "",
  price: "",
  type: "",
  modality: "",
  clientDuration: "",
  staffDuration: "",
  description: "",
  StaffId: "",
});
