import { AnyAction } from "redux";
import {
  DELETE_APPOINTMENT,
  GET_APPOINTMENTS,
  SET_APPOINTMENT,
  UPDATE_APPOINTMENT,
} from "../../actions/appointment";
import {
  AppointmentState,
  initAppointmentState,
} from "../../../interfaces/ReduxState";

export const AppointmentReducer = (
  state: AppointmentState = initAppointmentState(),
  action: AnyAction
) => {
  switch (action.type) {
    case SET_APPOINTMENT:
      return [ ...state, action.payload ];

    case GET_APPOINTMENTS:
      return action.payload;

    case UPDATE_APPOINTMENT:
      return state.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );

    case DELETE_APPOINTMENT:
      return state.filter((item) => item.id !== action.payload)

    default:
      return state;
  }
};
