import { Staff, StaffState } from "../../../../interfaces/Staff";

import style from "./StaffRow.module.css";
import removeSvg from "../../../../assets/icons/remove.svg";

interface Props {
  staff: Staff;
  handleDelete: (email: string) => void;
}

export default function StaffRow({ staff, handleDelete }: Props) {
  return (
    <div className={`table-text ${style.row}`}>
      <span>
        <input
          className={style.colorSelector}
          type="color"
          value={staff.color}
          disabled={true}
        />
      </span>
      <span>{`${staff.name} ${staff.surName}`}</span>
      <span>{staff.email}</span>
      <span>
        {staff.state === StaffState.PENDING && (
          <mark className={style.pending}>Pendiente</mark>
        )}
      </span>
      <button
        className="btn btn-outline-danger"
        type="button"
        onClick={() => handleDelete(staff.email!)}
      >
        <img src={removeSvg} alt="remove" />
      </button>
    </div>
  );
}
