import { CompanyHours } from "../../../../interfaces/Company";
import HourSelector from "./HourSelector/HourSelector";

import Checkbox from "../../../../components/Inputs/Checkbox";

import styles from "./DateSelector.module.css";

export interface Props {
  day: string;
  data: CompanyHours[] | null;
  onEnable: () => void;
  setHours: (index: number, newHour: CompanyHours) => void;
  onAdd: () => void;
  onRemove: (index: number) => void;
}

export default function DateSelector({ day, data, onEnable, setHours, onAdd, onRemove }: Props) {

  return (
    <div className={styles.selector}>
      <Checkbox
        name="day"
        label={day}
        value={data ? true : false}
        handleCheck={onEnable}
      />
      <div className={styles.hourContainer}>
        {
          data
            ? data?.map((data, index) =>
              <HourSelector
                data={data}
                onChange={(hours: CompanyHours) => setHours(index, hours)}
                onAddHour={onAdd}
                onRemoveHour={() => onRemove(index)}
              />)
            : <span>No disponible</span>
        }
      </div>
    </div>
  )
}
