export function formatDateHour(date: Date): string {
  // Date validate
  if (isNaN(date.getTime())) return "";

  // Date
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  // Hours
  let hour = date.getHours();
  const minute = date.getMinutes();
  const ampm = hour >= 12 ? "PM" : "AM";

  // Format hours
  hour = hour % 12;
  hour = hour ? hour : 12;
  const formatMinute = `00${minute}`.slice(-2);

  return `${day}/${month}/${year} - ${`0${hour}`.slice(-2)}:${`0${formatMinute}`.slice(-2)} ${ampm}`;
}
