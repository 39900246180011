import { ReactNode } from "react";

import styles from "./Header.module.css";

interface HeaderProps {
  children: ReactNode;
  text: string;
}

const Header: React.FC<HeaderProps> = ({ children, text }) => {
  return (
    <div className={styles.auth}>
      <div className={styles.header}>
        <h2>{text}</h2>
        {children}
      </div>
    </div>
  );
};

export default Header;
