import { collection, deleteDoc, doc, getDoc } from "firebase/firestore";
import { closeLoading, openLoading } from "../../redux/actions/loading";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Company, initCompany } from "../../interfaces/Company";
import { useEffect, useState } from "react";
import { RootState } from "../../interfaces/ReduxState";
import { auth, db } from "../../firebase/config";
import { getUser } from "../../redux/actions/profile";
import {
  initInvitation,
  Invitation as InvitationTS,
} from "../../interfaces/Invitations";
import axios from "axios";
import swal from "sweetalert";

import styles from "./Invitation.module.css";

export default function Invitation() {
  const dispatch = useDispatch();
  const redirect = useNavigate();
  const user = useSelector((state: RootState) => state.user);
  const location = useLocation();
  const [company, setCompany] = useState<Company>(initCompany());
  const [invitation, setInvitation] = useState<InvitationTS>(initInvitation());

  useEffect(() => {
    auth.authStateReady().then(async () => {
      await Promise.all([setTimeout(() => {}, 500)]);
      if (auth.currentUser?.uid) handleGetData();
      else {
        swal({
          icon: "info",
          text: "Para poder ver la invitacion por favor inicie sesión",
          buttons: {
            Iniciar: true,
            Registrarme: true,
          },
        }).then((response) => {
          if (response === "Iniciar") redirect("/login");
          if (response === "Registrarme") redirect("/signup");
        });
      }
    });
  }, []);

  const handleGetData = async () => {
    try {
      dispatch(openLoading());

      // Get url token
      const token = location.search
        ?.replace("?", "")
        ?.split("&")[0]
        ?.split("=")[1];

      // Get invitation data
      const invitationDoc = await getDoc(
        doc(collection(db, "Invitations"), token)
      );

      // Check invitation
      if (!invitationDoc.exists)
        swal({
          text: "La invitación ya no esta disponible",
          buttons: { Aceptar: true },
        }).then((response: string) => {
          if ((response = "Aceptar")) redirect("/");
        });

      // Get data
      const invitation = invitationDoc.data() as InvitationTS;

      if (!invitationDoc.exists()) {
        redirect("/");
        dispatch(closeLoading());
      } else {
        console.log(invitation);
        setInvitation(invitation);

        // Get company data
        const companyDoc = await getDoc(
          doc(collection(db, "Companies"), invitation.CompanyId)
        );

        if (companyDoc.exists()) {
          const company = companyDoc.data() as Company;
          company.id = companyDoc.id;
          setCompany(company);
          dispatch(closeLoading());
        } else {
          redirect("/");
          dispatch(closeLoading());
        }
      }
    } catch (error) {
      console.log(error);
      swal("Error", "Hubo un error desconocido", "error");
      dispatch(closeLoading());
    }
  };

  const handleCancel = async () => {
    swal({
      text: "¿Seguro que desea rechazar la invitación?",
      icon: "warning",
      buttons: {
        Si: true,
        No: true,
      },
    }).then(async (response: string) => {
      if (response === "Si") {
        try {
          dispatch(openLoading());
          await deleteDoc(doc(collection(db, "Invitations"), invitation.id));
          dispatch(closeLoading());
        } catch (error) {
          console.log(error);
          swal("Error", "Hubo un error desconocido", "error");
          dispatch(closeLoading());
        }
      }
    });
  };

  const handleSubmit = async () => {
    dispatch(openLoading());
    axios
      .post("/staff/accept-invitation", {
        userId: user.id,
        invitationId: invitation.id,
      })
      .then(() => {
        dispatch<any>(getUser());
        redirect("/");
        dispatch(closeLoading());
      })
      .catch((error) => {
        console.log(error);
        dispatch(closeLoading());
      });
  };

  return (
    <div className={styles.invitation}>
      {invitation?.id && (
        <div className={styles.card} data-aos="fade-up">
          <div className={styles.header}>
            <p>Has sido invitado a ser parte su staff</p>
          </div>
          <div className={styles.content}>
            <div className={styles.company}>
              <div className={styles.imgContainer}>
                <img src={company.logoImg} alt="logo" />
              </div>
              <span>{company.name}</span>
            </div>
            <div className={styles.btnContainer}>
              <button className="btn btn-primary" onClick={handleSubmit}>
                Aceptar
              </button>
              <button
                className="btn btn-outline-primary"
                onClick={handleCancel}
              >
                Rechazar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
