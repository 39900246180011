export interface Staff {
  id?: string;
  name: string;
  surName: string;
  email?: string;
  state: StaffState;
  color: string;
  photo: {
    url: string;
    fileName: string;
  };
}

export enum StaffState {
  PENDING = "Pendiente",
  WORKING = "Trabajando",
}

export interface StaffConfig {
  userId: string;
  email: string;
  state: StaffState;
  color: string;
  InvitationId?: string;
}

export const initStaff = (): Staff => ({
  name: "",
  surName: "",
  state: StaffState.PENDING,
  color: "",
  email: "",
  photo: {
    url: "",
    fileName: "",
  },
});
