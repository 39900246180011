import { LogoIcon } from "../LogoIcon";
import { Link } from "react-router-dom";

import styles from "./Footer.module.css";
import instagramSvg from "../../assets/redes/instagram.svg";
import linkedinSvg from "../../assets/redes/linkedin.svg";

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.column}>
          <div className={styles.logoContainer}>
            <Link className={styles.linkWittar} to={"/"}>
              <LogoIcon className={styles.logo} />
              <span className={styles.witar}>WITAR</span>
            </Link>
          </div>
          <div className={styles.textContainer}>
            <span className="text-footer-sm text-gray pe-4">
              Maximiza tu negocio aprende cómo nuestra plataforma simplifica la
              programación y la gestión de tu agenda de citas.
            </span>
          </div>
        </div>

        <div className={styles.columns}>
          <div className={styles.column}>
            <h3 className={styles.title}>Information</h3>
            <Link to="/login" className={styles.link}>
              Iniciar sesión
            </Link>
            <Link to="/signup" className={styles.link}>
              Registrarse
            </Link>
          </div>

          <div className={styles.column}>
            <h3 className={styles.title}>Platform</h3>
            <Link to="/terms-and-conditions" className={styles.link}>
              Condiciones de uso
            </Link>
            <Link to="/signup" className={styles.link}>
              ¿Quieres unirte?
            </Link>
          </div>

          <div className={styles.column}>
            <h3 className={styles.title}>Support</h3>
            <Link to="/contact" className={styles.link}>
              Contacto
            </Link>
            <Link to="/privacy-policy" className={styles.link}>
              Politica de privacidad
            </Link>
          </div>
        </div>
        <div className={styles.networkLinks}>
          <a href="https://www.instagram.com/witar.es" target="blank">
            <img src={instagramSvg} alt="instagramSvg" />
          </a>
          <a
            href="https://www.linkedin.com/company/witar-appointments"
            target="blank"
          >
            <img src={linkedinSvg} alt="linkedinSvg" />
          </a>
        </div>
      </div>

      <div className={styles.copyright}>
        <span className={styles.copyrightText}>
          Copyright © {new Date().getFullYear()}. All rights reserved.
        </span>
      </div>
    </footer>
  );
}
