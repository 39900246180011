import { CompanyState } from "../../../interfaces/Company";
import { AnyAction } from "redux";
import {
  GET_COMPANY,
  SET_COMPANY,
  TOGGLE_STATE_COMPANY,
  UPDATE_COMPANY,
} from "../../actions/company";
import {
  CompanyState as CompanyReduxState,
  initCompanyState,
} from "../../../interfaces/ReduxState";

export const CompanyReducer = (
  state: CompanyReduxState = initCompanyState(),
  action: AnyAction
) => {
  switch (action.type) {
    case SET_COMPANY:
      return action.payload;

    case GET_COMPANY:
      return action.payload;

    case UPDATE_COMPANY:
      return action.payload;

    case TOGGLE_STATE_COMPANY:
      return {
        ...state,
        state:
          state.state === CompanyState.ACTIVE
            ? CompanyState.INACTIVE
            : CompanyState.ACTIVE,
      };

    default:
      return state;
  }
};
