import { Link, useLocation, useNavigate } from "react-router-dom";
import { closeLoading, openLoading } from "../../redux/actions/loading";
import { useDispatch, useSelector } from "react-redux";
import { collection, doc, getDoc } from "firebase/firestore";
import { logInWithGoogle } from "../../redux/actions/profile";
import { setAppointment } from "../../redux/actions/appointment";
import { Appointment } from "../../interfaces/Appointment";
import { RootState } from "../../interfaces/ReduxState";
import { useState } from "react";
import { Company } from "../../interfaces/Company";
import { db } from "../../firebase/config";
import {
  LoginData,
  LoginError,
  initLogin,
  initLoginError,
} from "../../interfaces/Login";
import useSesion from "../../hooks/useSesion";
import swal from "sweetalert";

import Input from "../../components/Inputs/Input";
import Header from "../../components/Header/Header";
import CookiesModal from "../../components/CookiesModal/CookiesModal";

import styles from "./Login.module.css";
import svg1 from "../../assets/img/Vector-login-1.svg";
import svg2 from "../../assets/img/Vector-login-2.svg";

export default function Login() {
  const dispatch = useDispatch();
  const redirect = useNavigate();
  const userLogin = useSelector((state: RootState) => state.user);
  const location = useLocation();
  const sesion = useSesion();
  const [user, setUser] = useState<LoginData>(initLogin());
  const [error, setError] = useState<LoginError>(initLoginError());
  const [cookiesModal, setCookiesModal] = useState(true);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const name: string = e.target.name;
    const value: string = e.target.value;
    setUser({ ...user, [name]: value });
    setError({ ...error, [name]: "" });
  }

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();

    const query = new URLSearchParams(location.search);
    const redirectTo = query.get("redirectTo");
    const company = query.get("company");

    if (handleValidations()) {
      sesion
        .logIn(user)
        .then(async () => {
          if (redirectTo === "appointment" && company) {
            let localtAppointment = localStorage.getItem("appointment");
            if (!localtAppointment) throw new Error("Appointment not found");
            let newAppointment: Appointment = JSON.parse(localtAppointment);
            newAppointment.date = new Date(
              newAppointment.date! as unknown as string
            );
            const companySnap = await getDoc(
              doc(collection(db, "Companies"), company)
            );
            let companyData = companySnap.data() as Company;
            companyData.id = company;

            dispatch<any>(openLoading());
            dispatch<any>(
              setAppointment(companyData.id, newAppointment, sesion.user.email)
            )
              .then(() => {
                swal("Agendado", "Se agendo tu nueva cita", "success");
                localStorage.removeItem("appointment");
                redirect("/");
                dispatch<any>(closeLoading());
              })
              .catch((error: any) => {
                swal("Error", "Surgio un error al cargar el turno", "error");
                console.log(error);
                dispatch<any>(closeLoading());
              });
          }
        })
        .catch((error) => {
          if (error.message.includes("auth/wrong-password")) {
            setError({
              email: "",
              password: "Contraseña incorrecta",
            });
          } else if (error.message.includes("auth/invalid-login-credentials")) {
            setError({
              email: "Datos incorrectos",
              password: "Datos incorrectos",
            });
          } else if (error.message.includes("auth/user-not-found")) {
            setError({
              email: "El correo no esta registrado",
              password: "",
            });
          } else {
            console.log("Login", error);
            swal("Error", "No se pudo registrar, inténtelo más tarde", "error");
          }
        });
    }
  }

  /* VALIDATIONS */
  function handleValidations(): boolean {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const error = initLoginError();
    let value = true;

    if (user.email === "") {
      error.email = "Debes completar este campo";
      value = false;
    } else if (!emailRegex.test(user.email)) {
      error.email = "El formato del email es incorrecto";
      value = false;
    }

    if (user.password === "") {
      error.password = "Debes completar este campo";
      value = false;
    }

    setError(error);
    return value;
  }

  const handleAcept = () => {
    setCookiesModal(!cookiesModal);
  };

  const handleDecline = () => {
    setCookiesModal(!cookiesModal);
  };

  function handleLoginWithGoogle() {
    dispatch(openLoading());
    dispatch<any>(logInWithGoogle())
      .then(() => {
        dispatch(closeLoading());
      })
      .catch((error: any) => {
        dispatch(closeLoading());
        console.log(error);
        swal("Hubo un error al iniciar sesión con google");
      });
  }

  return (
    <div className={styles.container}>
      {cookiesModal && !userLogin.id && (
        <CookiesModal handleAcept={handleAcept} handleDecline={handleDecline} />
      )}
      <img className={styles.svgVector1} src={svg1} alt="" />
      <img className={styles.svgVector2} src={svg2} alt="" />
      <Header text="Iniciar sesión">
        <div className="d-flex flex-column justify-content-center align-items-center w-100">
          <form
            className="d-flex flex-column align-items-center justify-content-center max-w-500 w-100"
            onSubmit={handleSubmit}
          >
            <div className="d-flex flex-column gap-3 align-items-center justify-content-center  w-100 p-3">
              <Input
                name="email"
                label="Correo"
                value={user.email}
                error={error.email}
                handleChange={handleChange}
              />
              <Input
                type="password"
                name="password"
                label="Contraseña"
                value={user.password}
                error={error.password}
                handleChange={handleChange}
              />
              <div className="d-flex flex-row justify-content-end w-100 mt-n3">
                <Link
                  to="/reset-password"
                  className="text-gray text-body-sm cursor-pointer"
                >
                  ¿Has olvidado tu contraseña?
                </Link>
              </div>
            </div>
            <div className="d-flex flex-row w-100 align-items-center p-3">
              <button
                className="submit btn btn-primary bg-celeste w-100 border-celeste fw-bold"
                type="submit"
              >
                Iniciar Sesión
              </button>
            </div>
            <div className={styles.orLineContainer}>
              <div className={styles.orLine}></div>
              <span className="text-body-sm text-gray mb-1">o</span>
              <div className={styles.orLine}></div>
            </div>
            {/*             <button
              className={`${styles.btnShadow} btn btn-primary bg-white border-0 text-gray text-body-sm d-flex flex-row justify-content-start align-items-center px-3 py-2`}
              type="button"
              onClick={handleLoginWithGoogle}
            >
              <img
                src="/google.svg"
                width={20}
                height={20}
                alt="Google"
                className="me-3"
              />
              <span className="text-body-sm me-3 text-gray">
                Iniciar con Google
              </span>
            </button> */}
            <span className={styles.signupLink}>
              ¿No tienes cuenta? <Link to="/signup">Registrate</Link>
            </span>
          </form>
        </div>
      </Header>
    </div>
  );
}
