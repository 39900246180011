import styles from "./input.module.css";

interface InputProps {
  name: string;
  value: string | number | undefined;
  label: string;
  type?: string;
  error?: string;
  disabled?: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * @prop { string } name - Input name and id
 * @prop { any } value - Input value
 * @prop { string | number| undefined } label - Input label
 * @prop { string | undefined } type - Input type
 * @prop { string | undefined} error - Error message to display
 * @prop { () => void } handleChange - Function for onChange input
 * @prop { boolean } disabled - Input disables
 */
export default function Input({
  name,
  value,
  label,
  type = "text",
  error = "",
  disabled = false,
  handleChange,
}: InputProps) {
  return (
    <div className="w-100">
      <label htmlFor={name} className={`form-label ${styles.label}`}>
        {label}
      </label>
      <input
        id={name}
        name={name}
        className={`form-control ${styles.input} ${error && "is-invalid"}`}
        value={value}
        type={type}
        onChange={handleChange}
        disabled={disabled}
      />
      <small className="invalid-feedback">{error}</small>
    </div>
  );
}
