import { useSelector } from "react-redux";
import { RootState } from "../../../interfaces/ReduxState";
import { Link } from "react-router-dom";

import styles from "./Home.module.css";
import homeImg from "../../../assets/img/home.png";

const Home = () => {
  const user = useSelector((state: RootState) => state.user);

  return (
    <section className={styles.home}>
      <div className={styles.textContainer} data-aos="fade-right">
        <h1>
          Descubre la manera más fácil de <span>programar citas</span> con
          profesionales.
        </h1>
        <p>
          Seleccione profesional o zona y franja horaria preferida para reservar
          cita o consulta.
        </p>
        <div className={styles.buttonContainer}>
          <Link
            to={user.id ? "/company-form" : "/signup"}
            type="button"
            className="btn btn-primary"
          >
            Registrar mi negocio
          </Link>
        </div>
      </div>
      <div className={styles.imgContainer} data-aos="fade-left">
        <img className={styles.max_w_650} src={homeImg} alt="home" />
      </div>
    </section>
  );
};

export default Home;
