export default function daysUntilTrialEnds(startDate: Date): number {
  // Crear una fecha para el final del periodo de prueba de 30 días
  const trialEndDate = new Date(startDate);
  trialEndDate.setDate(trialEndDate.getDate() + 30);

  // Obtener la fecha actual
  const currentDate = new Date();

  // Calcular la diferencia en milisegundos
  const differenceInMillis = trialEndDate.getTime() - currentDate.getTime();

  // Convertir la diferencia de milisegundos a días
  const differenceInDays = Math.ceil(
    differenceInMillis / (1000 * 60 * 60 * 24)
  );

  return differenceInDays;
}
