import { closeLoading, openLoading } from "../../redux/actions/loading";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../interfaces/ReduxState";
import { Services } from "../../interfaces/Services";
import {
  deleteService,
  getServices,
  setService,
  updateService,
} from "../../redux/actions/services";
import swal from "sweetalert";
import useSesion from "../useSesion";

interface UseServices {
  data: Services[];
  set: (service: Services) => Promise<any>;
  get: () => Promise<any>;
  update: (service: Services) => Promise<any>;
  remove: (servicesId: string) => Promise<any>;
}

export function useServices(): UseServices {
  const dispatch = useDispatch();
  const user = useSesion();
  const services = useSelector((state: RootState) => state.services);
  const company = useSelector((state: RootState) => state.company);

  async function setServiceItem(service: Services): Promise<any> {
    dispatch(openLoading());
    dispatch<any>(setService(company.id!, service))
      .then(() => {
        dispatch(closeLoading());
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(closeLoading());
        swal(
          "Error",
          "No se pudo crear el servicio, inténtelo más tarde",
          "error"
        );
      });
  }

  async function getAllServices(): Promise<any> {
    dispatch(openLoading());
    dispatch<any>(getServices(company.id!))
      .then(() => {
        dispatch(closeLoading());
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(closeLoading());
        swal(
          "Error",
          "No se pudieron obtener los servicios, inténtelo más tarde",
          "error"
        );
      });
  }

  async function updateServiceItem(service: Services): Promise<any> {
    dispatch(openLoading());
    dispatch<any>(updateService(company.id!, service))
      .then(() => {
        dispatch(closeLoading());
      })
      .catch((error: any) => {
        console.log(error);
        dispatch(closeLoading());
        swal(
          "Error",
          "No se pudo actualizar el servicio, inténtelo más tarde",
          "error"
        );
      });
  }

  async function removeServiceById(servicesId: string): Promise<any> {
    swal({
      text: "¿Seguro que desea eliminar este servicio?",
      icon: "warning",
      buttons: {
        Si: true,
        No: true,
      },
    }).then((response: string) => {
      if (response === "Si") {
        dispatch(openLoading());
        dispatch<any>(deleteService(company.id!, servicesId))
          .then(() => {
            dispatch(closeLoading());
          })
          .catch((error: any) => {
            console.log(error);
            dispatch(closeLoading());
            swal("Error", "No se pudo eliminar el servicio", "error");
          });
      }
    });
  }

  function checkAccess<T extends Function>(callBack: T): T {
    const wrappedFunction: any = async (...args: any[]) => {
      if (await user.isSubscribed(true)) return await callBack(...args);
    };
    return wrappedFunction as T;
  }

  return {
    data: services,
    set: checkAccess(setServiceItem),
    get: getAllServices,
    update: checkAccess(updateServiceItem),
    remove: checkAccess(removeServiceById),
  };
}
