import { closeLoading, openLoading } from "../../../redux/actions/loading";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getMyAppointments } from "../../../redux/actions/myAppointment";
import { useAppointments } from "../../../hooks/useAppointment";
import { updateProfile } from "../../../redux/actions/profile";
import { Appointment } from "../../../interfaces/Appointment";
import { useNavigate } from "react-router-dom";
import { SubsState } from "../../../interfaces/Subscription";
import { RootState } from "../../../interfaces/ReduxState";
import { UserType } from "../../../interfaces/User";
import daysUntilTrialEnds from "../../../scripts/daysUntilTrialEnds";
import dateFormat from "../../../scripts/dateFormat";
import axios from "axios";
import swal from "sweetalert";

import Profile from "../../../components/Profile/Profile";
import MyAppointments from "../../../components/MyAppointements/MyAppointments";
import ProtectedRoute from "../../../components/ProtectedRoute/ProtectedRoute";

import styles from "./MyAcount.module.css";
import editSvg from "../../../assets/icons/edit.svg";

export default function MyAcount() {
  const dispatch = useDispatch();
  const redirect = useNavigate();
  const user = useSelector((state: RootState) => state.user);
  const appointments = useAppointments();
  const [rows, setRows] = useState<Appointment[]>([]);
  const [itemSelected, setItemSelected] = useState<number>(1);
  const myAppointments = useSelector(
    (state: RootState) => state.myAppointments
  );
  const [profile, setProfile] = useState<boolean>(false);

  useEffect(() => {
    setRows(
      myAppointments.filter((appoint) => {
        const now = new Date();
        if (
          itemSelected === 1 &&
          appoint.date &&
          appoint.date.getTime() > now.getTime()
        )
          return true;
        else if (
          itemSelected === 2 &&
          appoint.date &&
          appoint.date.getTime() < now.getTime()
        )
          return true;
        return false;
      })
    );
  }, [myAppointments, itemSelected]);

  useEffect(() => {
    dispatch<any>(openLoading());
    dispatch<any>(getMyAppointments(user.id!))
      .then(() => dispatch<any>(closeLoading()))
      .catch((error: Error) => {
        dispatch<any>(closeLoading());
        console.log(error);
      });
  }, [user]);

  // Edit an appointment
  const handleEditAppointment = (companyId: string, appointmetId: string) => {
    // Edit appointment form to add the edit option
    redirect(`/appointment/${companyId}?edit=${appointmetId}`);
  };

  // Redirect edit form
  function handleProfileForm() {
    setProfile(!profile);
  }

  function handleCancelSubscription() {
    swal({
      icon: "info",
      text: "¿Seguro que desea cancelar su suscripcion?",
      buttons: {
        Aceptar: true,
        Cancelar: true,
      },
    }).then((response: string) => {
      if (response === "Aceptar") {
        dispatch(openLoading());
        axios
          .patch(`/payment/cancel/${user.id}`, {
            subscriptionId: user.subscription!.id,
          })
          .then((response: any) => {
            const subscription = response.data.subscription;

            dispatch(openLoading());
            dispatch<any>(
              updateProfile({
                ...user,
                subscription: {
                  ...subscription,
                  date: new Date(subscription.date),
                },
              })
            )
              .then(() => dispatch(closeLoading()))
              .catch((error: any) => {
                console.log(error);
                dispatch(closeLoading());
              });
          })
          .catch((error) => {
            console.log(error);
            dispatch(closeLoading());
          });
      }
    });
  }

  function handleReactiveSubscription() {
    swal({
      icon: "info",
      text: "¿Seguro que desea reactivar su suscripcion?",
      buttons: {
        Aceptar: true,
        Cancelar: true,
      },
    }).then((response: string) => {
      if (response === "Aceptar") {
        dispatch(openLoading());
        axios
          .patch(`/payment/reactivate/${user.id}`, {
            subscriptionId: user.subscription!.id,
          })
          .then((response: any) => {
            const subscription = response.data.subscription;

            dispatch(openLoading());
            dispatch<any>(
              updateProfile({
                ...user,
                subscription: {
                  ...subscription,
                  date: new Date(subscription.date),
                },
              })
            )
              .then(() => dispatch(closeLoading()))
              .catch((error: any) => {
                console.log(error);
                dispatch(closeLoading());
              });
          })
          .catch((error) => {
            console.log(error);
            dispatch(closeLoading());
          });
      }
    });
  }

  return (
    <ProtectedRoute>
      <div className={styles.background}>
        {profile && <Profile handleClose={handleProfileForm} />}
        <div className={styles.container}>
          <div className={styles.data}>
            <div className={styles.profile}>
              <h5>Mi perfil</h5>
              <div className={styles.grid}>
                <div className={styles.profileContent}>
                  <img className={styles.profileImage} src={""} alt="" />
                </div>
                <h2>{user.name}</h2>
                <button
                  className={styles.iconBtn}
                  type="button"
                  onClick={handleProfileForm}
                >
                  <img src={editSvg} alt="edit" />
                </button>
                <span>{user.email}</span>
                {user.type === UserType.USER && (
                  <div className={styles.rolSection}>
                    <span className={`${styles.userRol} ${styles.userOwner}`}>
                      DUEÑO
                    </span>
                  </div>
                )}
                {user.type === UserType.STAFF && (
                  <div className={styles.rolSection}>
                    <span className={`${styles.userRol} ${styles.userStaff}`}>
                      STAFF
                    </span>
                  </div>
                )}
              </div>
            </div>
            {user.subscription?.id !== undefined &&
              (user.subscription.state === SubsState.ACTIVE ? (
                <div className={styles.subscription}>
                  <span>
                    Plan <b>Activo</b> - inicio{" "}
                    {dateFormat(user.subscription.date)}
                  </span>
                  <button
                    className={`btn btn-outline-primary ${styles.cancelBtn}`}
                    onClick={handleCancelSubscription}
                  >
                    Cancelar suscripción
                  </button>
                </div>
              ) : user.subscription.state === SubsState.REACTIVATE ? (
                <div className={styles.subscription}>
                  <span>
                    Plan <b>Activo</b> - A tiempo para reactivar su subscripcion
                  </span>
                  <button
                    className={`btn btn-outline-success ${styles.cancelBtn}`}
                    onClick={handleReactiveSubscription}
                  >
                    Reactivar suscripción
                  </button>
                </div>
              ) : user.subscription.state === SubsState.TRIAL ? (
                <div className={styles.subscription}>
                  <span>
                    Plan <b>Prueba</b> - Te quedan{" "}
                    {daysUntilTrialEnds(user.subscription.date)} días de prueba
                  </span>
                  <button
                    className={`btn btn-outline-success ${styles.cancelBtn}`}
                    onClick={handleReactiveSubscription}
                  >
                    Reactivar suscripción
                  </button>
                </div>
              ) : null)}
          </div>
          <div className={styles.appointment}>
            <div className={styles.tabs}>
              <button
                className={`${styles.tabItem} ${
                  itemSelected === 1 && styles.itemSelected
                }`}
                onClick={() => setItemSelected(1)}
              >
                Citas
              </button>
              <button
                className={`${styles.tabItem} ${
                  itemSelected === 2 && styles.itemSelected
                }`}
                onClick={() => setItemSelected(2)}
              >
                Historial
              </button>
            </div>
            <div className={styles.table}>
              {rows.length <= 0 ? (
                <div className={styles.empy}>
                  <span>
                    {itemSelected === 1 && "No hay citas pendientes"}
                    {itemSelected === 2 && "No hay historial de citas"}
                  </span>
                </div>
              ) : (
                rows.map((appointment: Appointment) => (
                  <MyAppointments
                    edit={true}
                    appointment={appointment}
                    onEdit={handleEditAppointment}
                    onDelete={appointments.remove}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </ProtectedRoute>
  );
}
