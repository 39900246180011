import { combineReducers } from "redux";
import { loadingReducer } from "./LoadingReducer";
import { ProfileReducer } from "./ProfileReducer";
import { CompanyReducer } from "./CompanyReducer";
import { ServicesReducer } from "./ServicesReducer";
import { StaffReducer } from "./StaffReducer";
import { AppointmentReducer } from "./AppointmentReducer/index";
import { MyAppointmentReducer } from "./MyAppointmentReducer";

const rootReducer = combineReducers({
  loading: loadingReducer,
  user: ProfileReducer,
  company: CompanyReducer,
  services: ServicesReducer,
  staff: StaffReducer,
  appointments: AppointmentReducer,
  myAppointments: MyAppointmentReducer,
});

export default rootReducer;
