import { closeLoading, openLoading } from "../../redux/actions/loading";
import { useDispatch } from "react-redux";
import { useState } from "react";
import {
  Contact,
  initContact,
  ContactError,
  initContactError,
} from "../../interfaces/Contact";
import axios from "axios";

import Input from "../../components/Inputs/Input";
import Header from "../../components/Header/Header";
import TextAreaInput from "../../components/Inputs/TextareaInput";

import styles from "./Contact.module.css";

export default function ContactForm() {
  const dispatch = useDispatch();
  const [contactData, setContactData] = useState<Contact>(initContact());
  const [error, setError] = useState<ContactError>(initContactError());

  function handleChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    setContactData({ ...contactData, [event.target.name]: event.target.value });
  }

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();

    if (handleValidations()) {
      dispatch(openLoading());
      axios
        .post("/contact", contactData)
        .then(() => dispatch(closeLoading()))
        .catch(() => dispatch(closeLoading()));
    }
  }

  function handleValidations(): boolean {
    let ContactError: ContactError = initContactError();
    let value = true;

    if (contactData.name === "") {
      error.name = "Este campo es obligatorio";
      value = false;
    }

    if (contactData.email === "") {
      error.email = "Este campo es obligatorio";
      value = false;
    }

    if (contactData.phone === "") {
      error.phone = "Este campo es obligatorio";
      value = false;
    }

    if (contactData.message === "") {
      error.message = "Este campo es obligatorio";
      value = false;
    }

    setError(ContactError);
    return value;
  }

  return (
    <Header text="Contactanos">
      <form className={styles.contactForm} onSubmit={handleSubmit}>
        <Input
          name="name"
          label="Nombre"
          value={contactData.name}
          error={error.email}
          handleChange={handleChange}
        />
        <Input
          name="phone"
          label="Telefono"
          value={contactData.phone}
          error={error.email}
          handleChange={handleChange}
        />
        <Input
          type="email"
          name="email"
          label="Email"
          value={contactData.email}
          error={error.email}
          handleChange={handleChange}
        />
        <TextAreaInput
          name="message"
          label="Mensaje"
          value={contactData.message}
          error={error.email}
          handleChange={handleChange}
        />
        <button className="btn btn-primary">Contactar</button>
      </form>
    </Header>
  );
}
