import Accordion from "react-bootstrap/Accordion";

import styles from "./Questions.module.css";

const Questions = () => {
  return (
    <section className={styles.questions}>
      <div className={`${styles.content} d-flex flex-column w-100 gap-3 pe-4`}>
        <h3 className="normal-subtitle text-center">
          <span className="bold-subtitle">¿Cómo podemos ayudarte?</span>
          <br />
          Preguntas y respuestas frecuentes
        </h3>
        <Accordion
          className={`${styles.accordion} w-100 d-flex flex-column gap-3`}
          defaultActiveKey="0"
        >
          <Accordion.Item
            className={`${styles.acordionLanding} bg-white border-0 w-100`}
            eventKey="1"
          >
            <Accordion.Header className="overhead fw-bold">
              ¿Cuánto tiempo antes de mi cita debo llegar?
            </Accordion.Header>
            <Accordion.Body className="border-0 text-gray text-body-sm">
              Te recomendamos llegar al menos 10-15 minutos antes de tu cita
              para completar cualquier formulario necesario y prepararte para tu
              servicio.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion
          className={`${styles.accordion} w-100 d-flex flex-column gap-3`}
        >
          <Accordion.Item
            className={`${styles.acordionLanding} bg-white border-0 w-100`}
            eventKey="2"
          >
            <Accordion.Header className="overhead fw-bold">
              ¿Puedo cancelar o modificar una cita existente?
            </Accordion.Header>
            <Accordion.Body className="border-0 text-gray text-body-sm">
              Sí, puedes modificar o cancelar tu cita en cualquier momento a
              través de tu cuenta en línea. Asegúrate de hacerlo con suficiente
              antelación.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion
          className={`${styles.accordion} w-100 d-flex flex-column gap-3`}
        >
          <Accordion.Item
            className={`${styles.acordionLanding} bg-white border-0 w-100`}
            eventKey="3"
          >
            <Accordion.Header className="overhead fw-bold">
              ¿Cómo puedo solicitar un servicio personalizado?
            </Accordion.Header>
            <Accordion.Body className="border-0 text-gray text-body-sm">
              Para solicitar un servicio personalizado, ponte en contacto con el
              proveedor de servicios a través de su perfil en nuestro sitio.
              Ellos estarán encantados de adaptar el servicio a tus necesidades.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion
          className={`${styles.accordion} w-100 d-flex flex-column gap-3`}
        >
          <Accordion.Item
            className={`${styles.acordionLanding} bg-white border-0 w-100`}
            eventKey="4"
          >
            <Accordion.Header className="overhead fw-bold">
              ¿Tienen opciones de reserva de último minuto?
            </Accordion.Header>
            <Accordion.Body className="border-0 text-gray text-body-sm">
              Sí, ofrecemos opciones de reserva de último minuto si hay
              disponibilidad. Puedes consultar la disponibilidad actual en
              nuestro sitio web.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion
          className={`${styles.accordion} w-100 d-flex flex-column gap-3`}
        >
          <Accordion.Item
            className={`${styles.acordionLanding} bg-white border-0 w-100`}
            eventKey="5"
          >
            <Accordion.Header className="overhead fw-bold">
              ¿Cómo puedo contactar a un proveedor de servicios específico antes
              de reservar?
            </Accordion.Header>
            <Accordion.Body className="border-0 text-gray text-body-sm">
              Puedes ponerte en contacto con un proveedor de servicios
              específico a través de su perfil en nuestro sitio web. Allí
              encontrarás opciones de contacto.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </section>
  );
};

export default Questions;
